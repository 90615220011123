import { myAxios } from "./axios"

export function getAdmins(payload, { page, perPage }) {
  let config = {
    params: {
      page,
      perPage,
      ...( payload|| {}),
    },
  }
  return myAxios.get("/admins", config)
}

export function getAdminsDropdown(data, { page, perPage }) {
  let config = {
    params: {
      page,
      perPage,
      username: data ? data.txt : null,
    },
  }
  return myAxios.get("/dropdown/admins", config)
}

export function getMyAdmins(payload, { page, perPage }) {
  let config = {
    params: {
      page,
      perPage,
      roleId: payload,
    },
  }
  return myAxios.get("/admin/my-admins", config)
}

export function addAdmin(data) {
  return myAxios.post("/admins/create", data)
}

export function editAdmin(id, data) {
  return myAxios.put("/admins/" + id + "/update", data)
}

export function getAdmin(id) {
  return myAxios.get("/admins/" + id)
}

export function deleteAdmin(id) {
  return myAxios.delete("/admins/" + id + "/delete")
}

export function changeAdminPassword(id, data) {
  return myAxios.put("/admins/" + id + "/change-password", data)
}

export function getProfile() {
  return myAxios.get("/me")
}

export function editProfile(data) {
  return myAxios.post("/admins/edit-profile", data)
}

export function getAllPermissions(data) {
  return myAxios.get("/permissions")
}


export function getLogs(_, { page, perPage }) {
  let config = {
    params: {
      page,
      perPage,
    },
  }
  return myAxios.get("/actionLogs", config)
}

