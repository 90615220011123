import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap";
// Formik validation
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useHistory, useParams } from "react-router-dom";
import ResultHandler from "../../../components/ResultHandler";
import { editCategory, getCategory } from "../../../api/categories";
import FormButtonHandler from "../../../components/FormButtonHandler";

import { useResponse } from "../../../hooks/useResponse";
import { useTranslation } from "react-i18next";
import { useGetInstence } from "../../../hooks/useGetInstence";
import CategoryForm from "./CategoryForm";
import { FORM_FIELDS, handleFormatFormData, VALIDATION_SCHEMA } from "./CONSTANT";
import { useRecoilState } from "recoil";
import langState from "../../../atom/lang";
import FormLangSwitcher from "../../../components/FormLangSwitcher";

const EditCategory = () => {
  let navigate = useHistory();

  const { t } = useTranslation();

  const params = useParams();
  const [lang] = useRecoilState(langState);

  const { instenceLoading, instence: editedCategory } = useGetInstence({
    apiMeth: () => getCategory(params.id),
    multilangFields: FORM_FIELDS.filter((f) => f.isMultilang).map((f) => f.key),
    getInstence: ({ category }) => {
      const { name, description, banner, icon, order } = category;

      return {
        name,
        description,
        order,
        banner_preview: banner,
        icon_preview: icon,
      };
    },
  });

  const { setData, loading, error, success } = useResponse(editCategory, () => {
    navigate.push("/categories");
  });

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: "",
      description: "",
      icon: null,
      banner: null,
      ...editedCategory,
    },
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: (values) => {
      const formData = handleFormatFormData(values, lang);
      setData({ type: "edit", id: params.id, data: formData });
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <div className="d-flex" style={{ justifyContent: "space-between" }}>
            <Breadcrumbs breadcrumbItem={"Edit Category"} />
            <FormLangSwitcher />
          </div>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  {editedCategory && (
                    <Form
                      className="needs-validation"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <CategoryForm validation={validation} />

                      <FormButtonHandler btnTxt="Save" loading={loading} />
                    </Form>
                  )}

                  <ResultHandler
                    loading={instenceLoading}
                    error={error}
                    success={success}
                    successMsg={"Updated Successfully!"}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditCategory;
