import React from "react"
import { Button } from "reactstrap"
import { useTranslation } from "react-i18next"

export default function FormButtonHandler({loading, type, handleClick, btnTxt}) {
  const { t } = useTranslation()

  return (
    <>
      {loading && (
        <Button size="lg" type="button">
          <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
          {t("Wait")}
        </Button>
      )}
      {!loading && (
        <>
        
            <Button
              color="primary"
              size="lg"
              type={type || "submit"}
              onClick={() => {
                if(handleClick){
                  handleClick()
                }
              }}
            >
              {t(btnTxt || "Save")}
            </Button>
        </>
      )}
    </>
  )
}
