import React, { useState } from "react";
//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import { usePagination } from "../../../hooks/usePagination";
import CustomTable from "../../../components/CustomTable/CustomTable";

import PaginationHandler from "../../../components/PaginationHandler";
import { getLogs } from "../../../api/admins";
import moment from "moment";
import LogDetailsModal from "./LogDetailsModal";
import { Button } from "reactstrap";

export default function LogsList() {
  const {
    response,
    loading,
    error,
    setPage,
    page,
    setPerPage,
    totalPages,
    setPayload,
  } = usePagination(getLogs);

  const logs = response?.data?.actionLog?.map((l) => ({
    ...l,
    message: l?.Admin?.username + " " + l.action + " a " + l.tableName + " ( "+ (l?.payload?.name || l?.payload?.title || l?.payload?.newData?.name || l?.payload?.newData?.title || l?.payload?.newData?.username || l?.payload?.username) +" )",
  }))

  const [log, setLog] = useState(null);

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title={"Logs"} isMainPage={true} />

        <CustomTable
          emptyMsg={"No Logs Found."}
          data={logs}
          loading={loading}
          error={error}
          cols={[
            {
              label: "Message",
              key: "message",
              style: { width: "80%" },
            },
            {
              label: "Date",
              key: "date",
              renderer: (row) =>
                moment(row.createdAt).format("DD MMM YYYY HH:mm"),
              style: { width: "20%" },
            },
          
          ]}
        />


        {response.data && !loading && (
          <PaginationHandler
            page={page}
            setPage={setPage}
            totalPages={totalPages}
          />
        )}
      </div>
    </div>
  );
}
