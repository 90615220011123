import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap";

import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { FORM_FIELDS } from "./CONSTANT";
import { FieldInput } from "../../../components/FieldInput";
import { useResponse } from "../../../hooks/useResponse";
import { getDevelopers } from "../../../api/developers";
import "./EventForm.css";
import { t } from "i18next";

export default function EventForm({ validation }) {
  const [developers, setDevelopers] = useState(null);
  const { response, setData } = useResponse(getDevelopers, (response) => {
    setDevelopers(response.data);
  });

  useEffect(() => {
    setData({});
  }, []);

  function handleDeveloperToggle(devId) {
    const currentSelected = validation.values.developers;
    if (currentSelected.includes(devId)) {
      validation.setFieldValue(
        "developers",
        currentSelected.filter((d) => d != devId)
      );
    } else {
      validation.setFieldValue("developers", [...currentSelected, devId]);
    }
  }

  return (
    <Row>
      {FORM_FIELDS.map((f) => (
        <FieldInput
          style={{ marginBottom: "30px" }}
          key={f.key}
          f={f}
          validation={validation}
        />
      ))}

     {new Date(validation.values.endDate) > new Date() && <div className="form-group">
        <Row>
          <Col md={3}>
            <Label>{t("Developers")}</Label>
          </Col>

          <Col md={9}>
            <Row style={{ rowGap: "25px" }}>
              {developers?.map((developer, i) => {
                const isSelected = validation.values.developers.includes(
                  developer.id
                );
                return (
                  <Col key={i} md={2}>
                    <div
                      onClick={() => handleDeveloperToggle(developer.id)}
                      className={
                        "custom-rounded dev-card " +
                        (isSelected ? "checked" : "")
                      }
                    >
                      <span className="custom-rounded">
                        {isSelected && <i className="bx bx-check"></i>}
                      </span>
                      <img className="custom-rounded" src={developer.image} />
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </div>}

    </Row>
  );
}
