import { Button, Col, Label, Row } from "reactstrap";
import ChooseImage from "../../../../components/ChooseImage";
import { useState } from "react";
import { useResponse } from "../../../../hooks/useResponse";
import { addEventMedia } from "../../../../api/events";

export default function CreateMediaForm({handleCancel, eventId, onSuccess}) {
  const {
    loading,
    error,
    setData ,
  } = useResponse(addEventMedia, (res) => {
    onSuccess(res.data.Media)
    handleCancel();
    
  });
  const [formData, setFormData] = useState({
    name: "dummy",
    type: "img",
    file: null,
    file_preview: null,
    description: "dummy",
    textUrl: "",
  });

  function handleSubmit() {
    const data = new FormData();
    Object.keys(formData).map((k) => {
      if(formData[k]){
        data.append(k, formData[k]);
      }
    });
    data.append("eventId", eventId);
    setData({ type: "add", data });
  }
  return (
    <Row>
      <Col md={4}>
        <Label>Media</Label>
        <select
          onChange={(e) => setFormData((f) => ({ ...f, file: null, file_preview: null, type: e.target.value }))}
          className="form-control"
        >
          <option value={"img"}>Image</option>
          <option value={"video"}>Video</option>
          <option value={"embedded"}>Embed</option>
        </select>
      </Col>
      <Col md={8}>
        {formData.type != "embedded" && (
          <ChooseImage
            key={formData.type}
            image={formData.file_preview}
            handleChooseImage={(v) => {
              setFormData((f) => ({
                ...f,
                textUrl: null,
                file: v,
                file_preview: v ? URL.createObjectURL(v) : null,
              }));
            }}
            hideLabel
            fileType={formData.type}
          />
        )}
        {formData.type == "embedded" && (
          <textarea
            placeholder="Add the embed code from the YouTube or Facebook page"
            style={{ width: "100%" }}
            className="form-control"
            onChange={(e) =>
              setFormData((f) => ({
                ...f,
                file: null,
                textUrl: e.target.value,
              }))
            }
            value={formData.textUrl}
          />
        )}
      </Col>

      <Col className="mt-4" md={12}>
        <div className="d-flex justify-content-between">
          <Button onClick={handleCancel} size="lg" color="dark">
            Cancel
          </Button>
          {loading && (
            <Button size="lg" color="dark">
              Wait
            </Button>
          )}
          {!loading && (
            <Button onClick={handleSubmit} size="lg" color="primary">
              Add
            </Button>
          )}
        </div>
      </Col>
    </Row>
  );
}
