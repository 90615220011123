import { myAxios } from "./axios"

export function getPages(data, { page, perPage }) {
    let config = {
        params: {
            page,
            perPage,
            filter: data

        },
    }
    return myAxios.get("/pages", config)
}

export function searchAndExport(data) {
    let config = {
        params: {
            filter: data
        },
    }
    return myAxios.get("/pages/export", config)
}

export function getPage(id) {
    return myAxios.get("/pages/" + id)
}

export function addPage(data) {
    return myAxios.post("/pages/create", data)
}

export function editPage(id, data) {
    return myAxios.put("/pages/" + id + "/update", data)
}

export function deletePage(id) {
    return myAxios.delete("/pages/" + id + "/delete")
}
