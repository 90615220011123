import React from "react";
import { Button, Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";

export default function PaginationHandler({ totalPages, page, setPage }) {
  const { t } = useTranslation();

  if (totalPages < 2) return <></>;

  return (
    <Row style={{ margin: "30px 0px 30px 0px", width: "100%" }}>
      <Col md={6}></Col>

      <Col md={6}>
        <Row className="justify-content-md-end justify-content-center align-items-center">
          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                disabled={page > 1 ? false : true}
                color="primary"
                onClick={() => {
                  setPage(1);
                }}
              >
                {"<<"}
              </Button>
              <Button
                color="primary"
                disabled={page > 1 ? false : true}
                onClick={() => {
                  setPage(page - 1);
                }}
              >
                {"<"}
              </Button>
            </div>
          </Col>
          <Col className="col-md-auto d-none d-md-block">
            {t("tables.Page")}{" "}
            <strong>
              {page} from {totalPages}
            </strong>
          </Col>
          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                disabled={totalPages > page ? false : true}
                color="primary"
                onClick={() => {
                  setPage(page + 1);
                }}
              >
                {">"}
              </Button>
              <Button
                disabled={totalPages > page ? false : true}
                color="primary"
                onClick={() => {
                  setPage(totalPages);
                }}
              >
                {">>"}
              </Button>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
