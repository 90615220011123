import React, { useEffect, useState } from "react";
//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { PERMISSIONS } from "../../../api/permissions";
import TableAction from "../../../components/TableAction";
import { usePagination } from "../../../hooks/usePagination";
import CustomTable from "../../../components/CustomTable/CustomTable";
import {
  deleteApplicant,
  exportApplicants,
  getApplicants,
} from "../../../api/applicants";
import PaginationHandler from "../../../components/PaginationHandler";
import { Button, Input, Label } from "reactstrap";
import { getCareers } from "../../../api/careers";
import { getDepartments } from "../../../api/departments";

import { ExportBtn } from "../../../components/ExportBtn";
import { useParams } from "react-router-dom";
import DateFilter from "../../../components/DateFilter";
import SearchSelectBox from "../../../components/SearchSelectBox";
import { t } from "i18next";
import moment from "moment";

export default function ApplicantsList() {
  const { type } = useParams();

  const hasCareer = type == "has-career";

  const {
    response,
    loading,
    error,
    setPage,
    page,
    setMeta,
    totalPages,
    setPayload,
  } = usePagination(getApplicants, null, false);

  useEffect(() => {
    setMeta((m) => ({
      ...m,
      initialLoad: true,
      payload: hasCareer ? { hasCareer } : {},
    }));
  }, [hasCareer]);

  const [filters, setFilters] = useState({
    from: null,
    to: null,
    departmentId: null,
    careerId: null,
    email: "",
    name: "",
  });

  function handleFilter() {
    setMeta((m) => ({
      page: 1,
      initialLoad: true,
      payload: {
        ...filters,
        ...(hasCareer ? {hasCareer} : {})
      },
    }));
  }

  function handleRefresh(){
    setPayload({
      ...filters,
      ...(hasCareer ? {hasCareer} : {})
    },)
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="d-flex justify-content-between">
          <Breadcrumbs
            title={hasCareer ? "Job Applicants" : "Generic Applicants"}
            isMainPage={true}
            //hasAddBtn={true}
            //addUrl="/add-applicant"
          />

          <ExportBtn
            api={exportApplicants}
            key={hasCareer ? "export-with-career" : "export-without-career"}
            apiMeta={{ ...(hasCareer ? { hasCareer } : {}), ...filters }}
          />
        </div>

        <div
          className="d-flex align-items-center filters-wrapper mb-4"
          style={{ gap: 20 }}
        >
          <DateFilter
            value={filters}
            onChange={(k, v) => setFilters((f) => ({ ...f, [k]: v }))}
          />

          <div className="d-flex align-items-center " style={{ gap: 10 }}>
            <Label>{t("Email")}</Label>
            <Input
              type="text"
              value={filters.email}
              onChange={(e) =>
                setFilters((f) => ({ ...f, email: e.target.value }))
              }
            />
          </div>

          <div className="d-flex align-items-center " style={{ gap: 10 }}>
            <Label>{t("Name")}</Label>
            <Input
              type="text"
              value={filters.name}
              onChange={(e) =>
                setFilters((f) => ({ ...f, name: e.target.value }))
              }
            />
          </div>

          {hasCareer && (
            <div
              className="d-flex align-items-center "
              style={{ gap: 10, width: "300px" }}
            >
              <Label>{t("Job post")}</Label>
              <div style={{ flex: 1 }}>
                <SearchSelectBox
                  value={filters.careerId}
                  onChange={(e) =>
                    setFilters((f) => ({ ...f, careerId: e?.value || null }))
                  }
                  label="name"
                  type="careers"
                  api={getCareers}
                />
              </div>
            </div>
          )}

          {!hasCareer && (
            <div
              className="d-flex align-items-center "
              style={{ gap: 10, width: "300px" }}
            >
              <Label>{t("Department")}</Label>
              <div style={{ flex: 1 }}>
                <SearchSelectBox
                  value={filters.departmentId}
                  onChange={(e) =>
                    setFilters((f) => ({
                      ...f,
                      departmentId: e?.value || null,
                    }))
                  }
                  label="name"
                  type="departments"
                  api={getDepartments}
                />
              </div>
            </div>
          )}

          <Button onClick={handleFilter} color="primary">
            {t("Search")}
          </Button>
        </div>

        <CustomTable
          key={hasCareer ? "table-with-career" : "table-without-career"}
          emptyMsg={"No Applicants Found."}
          data={response?.data?.applicants}
          loading={loading}
          error={error}
          cols={[
            { label: "Name", key: "name", style: { width: "10%" } },
            { label: "Email", key: "email", style: { width: "20%" } },
            { label: "Phone", key: "phone", style: { width: "15%" } },
            {
              label: "Date",
              key: "date",
              renderer: (row) => moment(row.createdAt).format("DD MMM YYYY"),
              style: { width: "15%" },
            },
            {
              label: "CV",
              key: "cv",
              style: { width: "10%" },
              renderer: (row) => (
                <a href={row.file} className="btn btn-primary btn-sm" download>
                  <i className="bx bx-download font-size-18"></i>
                </a>
              ),
            },

            ...(hasCareer
              ? [
                  {
                    label: "Job post",
                    key: "career",
                    renderer: (row) => row?.career?.name,
                    style: { width: "20%" },
                  },
                ]
              : [
                  {
                    label: "Department",
                    key: "department",
                    renderer: (row) => row?.department?.name,
                    style: { width: "20%" },
                  },
                ]),

            {
              label: "Action",
              key: "action",
              style: { width: "10%", textAlign: "right" },
              renderer: (row) => {
                return (
                  <>
                    <TableAction
                      hasDelete={true}
                      editPermission={PERMISSIONS.CATEGORY_EDIT}
                      deletePermission={PERMISSIONS.CATEGORY_DELETE}
                      editUrl={"/edit-applicant/" + row.id}
                      id={row.id}
                      deleteApi={deleteApplicant}
                      onDeleteSuccess={handleRefresh}
                    />
                  </>
                );
              },
            },
          ]}
        />

        {response.data && !loading && (
          <PaginationHandler
            page={page}
            setPage={setPage}
            totalPages={totalPages}
          />
        )}
      </div>
    </div>
  );
}
