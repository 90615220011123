import { getDepartments } from "../../../api/departments";
import * as Yup from "yup";
import { formatValidationErr } from "../../../hooks/utils";
import { getCareers } from "../../../api/careers";

export const FORM_FIELDS = [
  { key: "name", label: "Name" },
  { key: "email", label: "Email" },
  { key: "phone", label: "Phone" },
  {
    key: "careerId",
    label: "Career",
    type: "select",
    api: getCareers,
    apiResKey: "careers",
  },
  { key: "file", label: "CV", type: "file" },
];

export const INITIAL_VALS = {
  name: "",
  email: "",
  phone: "",
  careerId: null,
  file: null,
};

export function handleFormatFormData(values) {
  const formData = new FormData();

  Object.keys(values).map((key) => {
    if (values[key]) {
      formData.append(key, values[key]);
    }
  });

  return formData;
}

export const VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().required(formatValidationErr("Name")),
  email: Yup.string().required(formatValidationErr("Email")),
  phone: Yup.string().nullable().required(formatValidationErr("Phone")),
  careerId: Yup.number().nullable().required(formatValidationErr("Career")),

  file_preview: Yup.string().nullable().required(formatValidationErr("CV")),
});
