import { myAxios } from "./axios"

export function getRoles(data, { page, perPage }) {
  let config = {
    params: {
      page,
      perPage,
      name: data ? data.txt : null,
    },
  }
  return myAxios.get("/roles", config)
}

export function getRolesDropdown(data, { page, perPage }) {
  let config = {
    params: {
      page,
      perPage,
      name: data ? data.txt : null,
    },
  }
  return myAxios.get("/roles", config)
}

export function getRole(id) {
  return myAxios.get("/roles/" + id)
}

export function addRole(data) {
  return myAxios.post("/roles/create", data)
}

export function editRole(id, data) {
  return myAxios.put("/roles/" + id + "/update", data)
}

export function deleteRole(id) {
  return myAxios.delete("/roles/" + id + "/delete")
}
