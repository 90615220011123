import React, { useEffect } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap";
// Formik validation
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useHistory } from "react-router-dom";
import ResultHandler from "../../../components/ResultHandler";
import { addCareer } from "../../../api/careers";
import ChooseImage from "../../../components/ChooseImage";
import FormButtonHandler from "../../../components/FormButtonHandler";

import { useResponse } from "../../../hooks/useResponse";
import { useTranslation } from "react-i18next";

import CareerForm from "./CareerForm";
import {
  handleFormatFormData,
  INITIAL_VALS,
  VALIDATION_SCHEMA,
} from "./CONSTANT";
import FormLangSwitcher from "../../../components/FormLangSwitcher";
import { useRecoilState } from "recoil";
import langState from "../../../atom/lang";

const AddCareer = () => {
  const { t } = useTranslation();
  let navigate = useHistory();
  const [lang] = useRecoilState(langState)

  const { setData, loading, error, success } = useResponse(addCareer, () => {
    navigate.push("/careers");
  });

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: INITIAL_VALS,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: (values) => {
      const formData = handleFormatFormData(values, lang);

      setData({ data: formData, type: "add" });
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <div className="d-flex" style={{ justifyContent: "space-between" }}>
            <Breadcrumbs breadcrumbItem={"Add Job Post"} />
            <FormLangSwitcher />
          </div>
          <Card>
            <CardBody>
              <Row>
                <Col xl="12">
                  <Form
                    className="needs-validation"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <CareerForm validation={validation} />
                    <ResultHandler
                      error={error}
                      success={success}
                      successMsg={"Created Successfully!"}
                    />
                    <FormButtonHandler btnTxt="Add" loading={loading} />
                  </Form>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddCareer;
