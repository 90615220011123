import { myAxios } from "./axios"

export function getSeos(data) {
    let config = {
        params: {
        
            ...(data || {})            

        },
    }
    return myAxios.get("/seo", config)
}


export function getSeo(id) {
    return myAxios.get("/seo/" + id)
}

export function editSeo(id, data) {
    return myAxios.put("/seo/" + id + "/update", data)
}