import { myAxios } from "./axios"

export function getCareers(data, { page, perPage }) {
    let config = {
        params: {
            page,
            perPage,
            filter: data

        },
    }
    return myAxios.get("/careers", config)
}

export function searchAndExport(data) {
    let config = {
        params: {
            filter: data
        },
    }
    return myAxios.get("/careers/export", config)
}

export function getCareer(id) {
    return myAxios.get("/careers/" + id)
}

export function addCareer(data) {
    return myAxios.post("/careers/create", data)
}

export function editCareer(id, data) {
    return myAxios.put("/careers/" + id + "/update", data)
}

export function deleteCareer(id) {
    return myAxios.delete("/careers/" + id + "/delete")
}
