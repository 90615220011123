import React from "react";
//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { PERMISSIONS } from "../../../api/permissions";
import TableAction from "../../../components/TableAction";
import { usePagination } from "../../../hooks/usePagination";
import CustomTable from "../../../components/CustomTable/CustomTable";
import { deleteCareer, getCareers } from "../../../api/careers";
import PaginationHandler from "../../../components/PaginationHandler";

export default function CareersList() {
  const {
    response,
    loading,
    error,
    setPage,
    page,
    setPerPage,
    totalPages,
    setPayload,
  } = usePagination(getCareers);
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs
          title={"Careers"}
          isMainPage={true}
          hasAddBtn={true}
          addUrl="/add-career"
        />

        <CustomTable
          emptyMsg={"No Careers Found."}
          data={response?.data}
          loading={loading}
          error={error}
          cols={[
            { label: "Name", key: "name", style: { width: "20%" } },
            { label: "Type", key: "workType", style: { width: "20%" } },
            {
              label: "Location",
              key: "location",
              renderer: (row) => row.location.name,
              style: { width: "20%" },
            },
            {
              label: "Department",
              key: "department",
              renderer: (row) => row.department.name,
              style: { width: "20%" },
            },

            {
              label: "Action",
              key: "action",
              style: { width: "20%", textAlign: "right" },
              renderer: (row) => {
                return (
                  <>
                    <TableAction
                      hasEdit={true}
                      hasDelete={true}
                      editPermission={PERMISSIONS.CATEGORY_EDIT}
                      deletePermission={PERMISSIONS.CATEGORY_DELETE}
                      editUrl={"/edit-career/" + row.id}
                      id={row.id}
                      deleteApi={deleteCareer}
                      onDeleteSuccess={() => setPayload({})}
                    />
                  </>
                );
              },
            },
          ]}
        />

        {response.data && !loading && (
          <PaginationHandler
            page={page}
            setPage={setPage}
            totalPages={totalPages}
          />
        )}
      </div>
    </div>
  );
}
