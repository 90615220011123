import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap";
// Formik validation
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useHistory, useParams } from "react-router-dom";
import ResultHandler from "../../../components/ResultHandler";
import { editService, getService } from "../../../api/services";
import FormButtonHandler from "../../../components/FormButtonHandler";
import useCustomValidation from "../validationSchema";
import { useResponse } from "../../../hooks/useResponse";
import { useTranslation } from "react-i18next";
import { useGetInstence } from "../../../hooks/useGetInstence";
import ServiceForm from "./ServiceForm";
import {
  FORM_FIELDS,
  handleFormatFormData,
  INITIAL_VALS,
  VALIDATION_SCHEMA,
} from "./CONSTANT";
import FormLangSwitcher from "../../../components/FormLangSwitcher";
import langState from "../../../atom/lang";
import { useRecoilState } from "recoil";

const EditService = () => {
  let navigate = useHistory();
  const [lang] = useRecoilState(langState)

  const params = useParams();

  const { instenceLoading, instence: editedService } = useGetInstence({
    apiMeth: () => getService(params.id),
    multilangFields: ['content_default',...FORM_FIELDS.filter(f => f.isMultilang).map(f => f.key)],
    getInstence: ({Service}) => {
      const { title, description, img, content } = Service;

      return {
        title,
        description,
        content_default: {
          en: content.en ? JSON.parse(content.en) : null,
          ar: content.ar ? JSON.parse(content.ar) : null

        },
        image_preview: img,
      }
    }
  });


  const { setData, loading, error, success } = useResponse(editService, () => {
    navigate.push("/services");
  });

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      ...INITIAL_VALS,
      ...editedService,
    },
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: (values) => {
      const formData = handleFormatFormData(values, lang);
      setData({ type: "edit", id: params.id, data: formData });
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <div className="d-flex justify-content-between">
            <Breadcrumbs title={"Edit Service"} />
            <FormLangSwitcher />
          </div>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  {editedService && (
                    <Form
                      className="needs-validation"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <ServiceForm validation={validation} />
                      <ResultHandler
                        loading={instenceLoading}
                        error={error}
                        success={success}
                        successMsg={"Updated Successfully!"}
                      />
                      <FormButtonHandler btnTxt="Save" loading={loading} />
                    </Form>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditService;
