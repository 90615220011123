import React, { useEffect, useState } from "react";
//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { PERMISSIONS } from "../../../api/permissions";
import TableAction from "../../../components/TableAction";
import { usePagination } from "../../../hooks/usePagination";
import CustomTable from "../../../components/CustomTable/CustomTable";

import PaginationHandler from "../../../components/PaginationHandler";

import { ExportBtn } from "../../../components/ExportBtn";
import { getNewsletterSubmissions } from "../../../api/newsletter";
import { exportNewsletterSubmissions } from "../../../api/newsletter";
import { deleteNewsletterSubmission } from "../../../api/newsletter";
import { Button, Input, Label } from "reactstrap";
import { t } from "i18next";
import DateFilter from "../../../components/DateFilter";
import moment from "moment/moment";

export default function NewsSubmissionsList() {
  const {
    response,
    loading,
    error,
    setPage,
    page,
    setMeta,
    totalPages,
    setPayload,
  } = usePagination(getNewsletterSubmissions);

  const [filters, setFilters] = useState({
    from: null,
    to: null,
    email: "",
  });

  function handleFilter() {
    setMeta((m) => ({
      page: 1,
      initialLoad: true,
      payload: filters,
    }));
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="d-flex justify-content-between">
          <Breadcrumbs title={"Newsletter Submissions"} isMainPage={true} />

          <ExportBtn apiMeta={filters} api={exportNewsletterSubmissions} />
        </div>

        <div className="d-flex align-items-center filters-wrapper mb-4" style={{ gap: 20 }}>
          <DateFilter
            value={filters}
            onChange={(k, v) => setFilters((f) => ({ ...f, [k]: v }))}
          />

          <div className="d-flex align-items-center " style={{ gap: 10 }}>
            <Label>{t("Email")}</Label>
            <Input
              type="text"
              value={filters.email}
              onChange={(e) =>
                setFilters((f) => ({ ...f, email: e.target.value }))
              }
            />
          </div>

          <Button onClick={handleFilter} color="primary">{t("Search")}</Button>
        </div>

        <CustomTable
          emptyMsg={"No Submissions Found."}
          data={response?.data?.newsletters}
          loading={loading}
          error={error}
          cols={[
            { label: "Email", key: "email", style: { width: "20%" } },
            { label: "Date", key: "date", renderer: (row) => moment(row.createdAt).format('DD MMM YYYY'), style: { width: "20%" } },

            {
              label: "Action",
              key: "action",
              style: { width: "60%", textAlign: "right" },
              renderer: (row) => {
                return (
                  <>
                    <TableAction
                      hasDelete={true}
                      editPermission={PERMISSIONS.CATEGORY_EDIT}
                      deletePermission={PERMISSIONS.CATEGORY_DELETE}
                      id={row.id}
                      deleteApi={deleteNewsletterSubmission}
                      onDeleteSuccess={() => setPayload({})}
                    />
                  </>
                );
              },
            },
          ]}
        />

        {response.data && !loading && (
          <PaginationHandler
            page={page}
            setPage={setPage}
            totalPages={totalPages}
          />
        )}
      </div>
    </div>
  );
}
