
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import React, { useState } from "react";
import { usePagination } from "../../../hooks/usePagination";
import ResultHandler from "../../../components/ResultHandler";
import PaginationHandler from "../../../components/PaginationHandler";
import { deleteLocation, getLocations } from "../../../api/locations";
import TableAction from "../../../components/TableAction";
import { PERMISSIONS } from "../../../api/permissions";
import CustomTable from "../../../components/CustomTable/CustomTable";
import LocationFormModal from "./LocationFormModal";

export default function LocationsList() {
  const {
    response,
    loading,
    error,
    setPage,
    page,
    setPerPage,
    totalPages,
    setPayload,
  } = usePagination(getLocations);

  const [formModalShow, setFormModalShow] = useState(false);
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs
          title={"Locations"}
          hasAddBtn={true}
          onAddBtnClick={() => setFormModalShow(true)}
          isMainPage={true}
          addTxt="Add New Location"

        />
        <CustomTable
          emptyMsg={"No Locations Found."}
          data={response?.data}
          loading={loading}
          error={error}
          cols={[
            { label: "Name", key: "name", style: { width: "25%" } },

            {
              label: "Action",
              key: "action",
              style: { width: "75%", textAlign: "right" },
              renderer: (row) => {
                return (
                  <TableAction
                    hasEdit={true}
                    hasDelete={true}
                    editPermission={PERMISSIONS.CATEGORY_EDIT}
                    deletePermission={PERMISSIONS.CATEGORY_DELETE}
                    id={row.id}
                    deleteApi={deleteLocation}
                    onDeleteSuccess={() => setPayload({})}
                    onEditClick={() => {
                      setFormModalShow(row);
                    }}
                  />
                );
              },
            },
          ]}
        />
        {response.data && !loading && (
          <PaginationHandler
            page={page}
            setPage={setPage}
            totalPages={totalPages}
          />
        )}
        {formModalShow && <LocationFormModal
          key={formModalShow}
          isShow={formModalShow ? true : false}
          handleClose={() => setFormModalShow(false)}
          handleRefresh={() => setPayload({})}
          locationId={typeof formModalShow == "object" ? formModalShow.id : null}
        />}
      </div>
    </div>
  );
}
