import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";

export default function TableHeaderHandler(props) {
  const { t } = useTranslation();
  const permissions = JSON.parse(localStorage.getItem("permissions")) || [];

  return (
    <Row style={{ justifyContent: "space-between" }}>
      <div className={"d-flex"}>
        <div>{props.children}</div>

        
      </div>
    </Row>
  );
}
