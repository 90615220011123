import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  InputGroup,
  InputGroupText,
  Button,
} from "reactstrap"
import { useFormik } from "formik"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import ResultHandler from "../../../components/ResultHandler"
import { addAdmin } from "../../../api/admins"
import FormButtonHandler from "../../../components/FormButtonHandler"
import { getRolesDropdown } from "../../../api/roles"
import SearchSelectBox from "../../../components/SearchSelectBox"
import { useResponse } from "../../../hooks/useResponse"
import { useTranslation } from "react-i18next"
import useCustomValidation from "../validationSchema"

const AddAdmin = () => {
  const { t } = useTranslation()
  const { addAdminSchema } =  useCustomValidation()

  let navigate = useHistory()
  const { setData, loading, error, success } = useResponse(addAdmin, () => {
    navigate.push("/admins")
  })

  const [passwordSee, setPasswordSee] = useState(false)
  const [confirmPasswordSee, setConfirmPasswordSee] = useState(false)

  // Form validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
      // categories: null,
      isActive: true,
      role: null,
    },
    validationSchema: addAdminSchema,
    onSubmit: values => {
      let data = {
        ...values,
        roleId: values.role.value,
        // Categories: values.categories
        //   ? values.categories.map(c => c.value)
        //   : null,
      }
      setData({ data, type: "add" })
    },
  })

  function generatePassword() {
    let length = 25
    const chars =
      "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ"
    let password = ""
    const array = new Uint32Array(length)
    window.crypto.getRandomValues(array)
    for (let i = 0; i < length; i++) {
      password += chars[array[i] % chars.length] // % operator returns remainder of division
    }

    validation.setFieldValue("password", password)
    validation.setFieldValue("confirmPassword", password)
    validation.setFieldTouched("confirmPassword", false)
  }

  return (
    <div className="page-content">
      <Container fluid={true}>
        <Breadcrumbs
          title={t("btns.Add") + " " + t("pages.Admin")}
        />
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <Form
                  className="needs-validation"
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                  }}
                >
                  <Row>
                    <Col md="6">
                      <FormGroup className="mb-3">
                        <Label>{t("Name")}</Label>
                        <Input
                          name="username"
                          placeholder={t("Name")}
                          type="text"
                          className="form-control"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.username || ""}
                          invalid={
                            validation.touched.username &&
                            validation.errors.username
                              ? true
                              : false
                          }
                        />
                        {validation.touched.username &&
                        validation.errors.username ? (
                          <FormFeedback type="invalid">
                            {validation.errors.username}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup className="mb-3">
                        <Label>{t("forms.Email")}</Label>
                        <Input
                          name="email"
                          placeholder={t("forms.Email")}
                          type="email"
                          className="form-control"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup className="mb-3">
                        <Label>{t("forms.Password")}</Label>
                        <InputGroup>
                          <InputGroupText
                            onClick={() => {
                              setPasswordSee(!passwordSee)
                            }}
                          >
                            {passwordSee && <i className="fas fa-eye"></i>}
                            {!passwordSee && (
                              <i className="fas fa-eye-slash"></i>
                            )}
                          </InputGroupText>
                          <Input
                            name="password"
                            placeholder={t("forms.Password")}
                            type={passwordSee ? "text" : "password"}
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}
                        </InputGroup>
                      </FormGroup>
                    </Col>

                    <Col md="6">
                      <FormGroup className="mb-3">
                        <Label>{t("forms.Confirm Password")}</Label>
                        <InputGroup>
                          <InputGroupText
                            onClick={() => {
                              setConfirmPasswordSee(!confirmPasswordSee)
                            }}
                          >
                            {confirmPasswordSee && (
                              <i className="fas fa-eye"></i>
                            )}
                            {!confirmPasswordSee && (
                              <i className="fas fa-eye-slash"></i>
                            )}
                          </InputGroupText>
                          <Input
                            name="confirmPassword"
                            placeholder={t("forms.Confirm Password")}
                            type={confirmPasswordSee ? "text" : "password"}
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.confirmPassword || ""}
                            invalid={
                              validation.touched.confirmPassword &&
                              validation.errors.confirmPassword
                                ? true
                                : false
                            }
                          />
                          {validation.touched.confirmPassword &&
                          validation.errors.confirmPassword ? (
                            <FormFeedback type="invalid">
                              {validation.errors.confirmPassword}
                            </FormFeedback>
                          ) : null}
                        </InputGroup>
                      </FormGroup>
                    </Col>

                    <Col md="6">
                      <FormGroup className="mb-3">
                        <Label>{t("forms.Role")}</Label>
                        <SearchSelectBox
                          name="role"
                          error={
                            validation.touched.role && validation.errors.role
                          }
                          isMulti={false}
                          value={validation.values.role?.value || null}
                          type="roles"
                          label="name"
                          api={getRolesDropdown}
                          handleFocus={() => {
                            validation.setFieldTouched("role")
                          }}
                          handleBlur={validation.handleBlur}
                          setValue={option => {
                            validation.setFieldValue("role", option)
                          }}
                        />
                      </FormGroup>
                    </Col>

                  </Row>

                  <FormButtonHandler btnTxt="Add" loading={loading} />

                  <ResultHandler
                    error={error}
                    success={success}
                    successMsg={"Created Successfully!"}
                  />
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default AddAdmin
