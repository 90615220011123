import React, { useEffect } from "react";

import { Row, Col, Form, Modal, ModalBody, Button, FormFeedback } from "reactstrap";
// Formik validation
import { useFormik } from "formik";
import ResultHandler from "../../../components/ResultHandler";

import FormButtonHandler from "../../../components/FormButtonHandler";
import { useResponse } from "../../../hooks/useResponse";

import { t } from "i18next";
import { changeAdminPassword } from "../../../api/admins";
import PasswordField from "./PasswordField";
import { useParams } from "react-router-dom";
import * as Yup from 'yup'
import { formatValidationErr } from "../../../hooks/utils";

const ChangePasswordModal = ({ isShow, handleClose }) => {

    const {id} = useParams()

  const { setData, loading, error, success } = useResponse(
    changeAdminPassword,
    () => {
      handleClose();
    }
  );

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      password: "",
      pass_confirm: "",
    },
    validationSchema: Yup.object({
        password: Yup.string().required(formatValidationErr('New password')),
        pass_confirm: Yup.string().required(formatValidationErr('Confirm password')).test(
            'passwords-match', 
            'Passwords must match', 
            function (value) { return this.parent.password === value }
        )

    }),
    onSubmit: (values) => {
      const data = {
        password: values.password,
      };

      setData({ data, id, type: "edit" });
    },
  });

  return (
    <Modal size="md" isOpen={isShow} toggle={handleClose} centered={true}>
      <ModalBody className="custom-rounded py-5 px-5">
        <Row>
          <Col lg={12}>
            <h2 className="mb-4">{t("Change password")}</h2>
          </Col>

          <Col xl="12">
            <Form
              className="needs-validation"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <PasswordField
                label={"New Password"}
                name="password"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.password || ""}
                invalid={
                  validation.touched.password && validation.errors.password
                    ? true
                    : false
                }
              >
                {validation.touched.password && validation.errors.password ? (
                  <FormFeedback type="invalid">
                    {validation.errors.password}
                  </FormFeedback>
                ) : null}
              </PasswordField>

              <PasswordField
                label={"Confirm Password"}
                name="pass_confirm"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.pass_confirm || ""}
                invalid={
                  validation.touched.pass_confirm &&
                  validation.errors.pass_confirm
                    ? true
                    : false
                }
              >
                {validation.touched.pass_confirm &&
                validation.errors.pass_confirm ? (
                  <FormFeedback type="invalid">
                    {validation.errors.pass_confirm}
                  </FormFeedback>
                ) : null}
              </PasswordField>

              <ResultHandler
                error={error}
                success={success}
                successMsg={"Saved Successfully!"}
              />
              <div className="d-flex mt-5 justify-content-between">
                <Button size="lg" color="dark">
                  {t("Cancel")}
                </Button>
                <FormButtonHandler btnTxt={"Save"} loading={loading} />
              </div>
            </Form>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default ChangePasswordModal;
