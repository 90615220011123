import Breadcrumbs from "../../../components/Common/Breadcrumb";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { usePagination } from "../../../hooks/usePagination";
import PaginationHandler from "../../../components/PaginationHandler";
import { deleteService, getServices } from "../../../api/services";
import TableAction from "../../../components/TableAction";
import { PERMISSIONS } from "../../../api/permissions";

import CustomTable from "../../../components/CustomTable/CustomTable";

export default function ServicesList() {
  const {
    response,
    loading,
    error,
    setPage,
    page,
    setPerPage,
    totalPages,
    setPayload,
  } = usePagination(getServices);
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs
          title={"Services"}
          hasAddBtn={true}
          addUrl={"/add-service"}
          isMainPage={true}
        />

        <CustomTable
          emptyMsg={"No Services Found."}
          data={response?.data?.services}
          loading={loading}
          error={error}
          cols={[
            {
              label: "Icon",
              key: "icon",
              renderer: (row) => (
                <img className="custom-rounded" src={row.img} />
              ),
              style: {
                width: "5%",
              },
            },
            { label: "Title", key: "title", style: { width: "20%" } },
            {
              label: "Description",
              key: "description",
              style: { width: "55%" },
            },

            {
              label: "Action",
              key: "action",
              style: { width: "20%", textAlign: "right" },
              renderer: (row) => {
                return (
                  <>
                    <TableAction
                      hasEdit={true}
                      hasDelete={true}
                      editPermission={PERMISSIONS.CATEGORY_EDIT}
                      deletePermission={PERMISSIONS.CATEGORY_DELETE}
                      editUrl={"/edit-service/" + row.id}
                      id={row.id}
                      deleteApi={deleteService}
                      onDeleteSuccess={() => setPayload({})}
                    />
                  </>
                );
              },
            },
          ]}
        />

        {response.data && !loading && (
          <PaginationHandler
            page={page}
            setPage={setPage}
            totalPages={totalPages}
          />
        )}
      </div>
    </div>
  );
}
