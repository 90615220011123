import authState from "../atom/auth"
import i18next from "i18next"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useRecoilState } from "recoil"


export const useLogout = () => {
  const [logout, setLogout] = useState(null)
  const history = useHistory()
  const [isLogged, setIsLogged] = useRecoilState(authState)

  useEffect(() => {
    if (logout) {
      localStorage.setItem("token", "")
      localStorage.setItem("user", "")
      localStorage.setItem("permissions", "")
    
      setIsLogged(false)
      setLogout(false)
      history.push("/login") 
    }
  }, [logout])

  return { setLogout }
}
