import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap";
// Formik validation
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useHistory, useParams } from "react-router-dom";
import ResultHandler from "../../../components/ResultHandler";
import { editSection, getSection } from "../../../api/sections";
import FormButtonHandler from "../../../components/FormButtonHandler";
import useCustomValidation from "../validationSchema";
import { useResponse } from "../../../hooks/useResponse";
import { useTranslation } from "react-i18next";
import { useGetInstence } from "../../../hooks/useGetInstence";
import SectionForm from "./SectionForm";
import {
  FORM_FIELDS,
  handleFormatFormData,
  INITIAL_VALS,
  VALIDATION_SCHEMA,
} from "./CONSTANT";
import { useRecoilState } from "recoil";
import langState from "../../../atom/lang";
import FormLangSwitcher from "../../../components/FormLangSwitcher";

const EditSection = () => {
  let navigate = useHistory();
  const [lang] = useRecoilState(langState);

  const params = useParams();

  const { instenceLoading, instence: editedSection } = useGetInstence({
    apiMeth: () => getSection(params.id),
    multilangFields: [
      "content_default",
      ...FORM_FIELDS.filter((f) => f.isMultilang).map((f) => f.key),
    ],
    getInstence: ({ Section }) => {
      const { title, type, media, content, background, token } = Section;
      return { 
        title,
        type,
        media_preview: media,
        background_preview: background,
        token,
        content_default: {
          en: content?.en ? JSON.parse(content.en) : null,
          ar: content?.ar ? JSON.parse(content.ar) : null,
        },
      };
    },
  });

  const { setData, loading, error, success } = useResponse(editSection, () => {
    navigate.goBack();
  });

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      ...INITIAL_VALS,
      ...editedSection,
    },
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: (values) => {
      const formData = handleFormatFormData(values, lang);
      setData({ type: "edit", id: params.id, data: formData });
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <div className="d-flex justify-content-between">
            <Breadcrumbs title={"Edit Section"} />
            <FormLangSwitcher />
          </div>

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  {editedSection && (
                    <Form
                      className="needs-validation"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <SectionForm sectionToken={editedSection.token} validation={validation} />
                      <ResultHandler
                        loading={instenceLoading}
                        error={error}
                        success={success}
                        successMsg={"Updated Successfully!"}
                      />
                      <FormButtonHandler btnTxt="Save" loading={loading} />
                    </Form>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditSection;
