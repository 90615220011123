import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap";
// Formik validation
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useHistory, useParams } from "react-router-dom";
import ResultHandler from "../../../components/ResultHandler";
import { editPage, getPage } from "../../../api/pages";
import FormButtonHandler from "../../../components/FormButtonHandler";
import useCustomValidation from "../validationSchema";
import { useResponse } from "../../../hooks/useResponse";
import { useTranslation } from "react-i18next";
import { useGetInstence } from "../../../hooks/useGetInstence";
import PageForm from "./PageForm";
import { handleFormatFormData, INITIAL_VALS } from "./CONSTANT";

const EditPage = () => {
  let navigate = useHistory();
  const { pageSchema } = useCustomValidation();

  const { t } = useTranslation();

  const params = useParams();
  const [editedPage, setEditedPage] = useState(null);

  const { instenceLoading, instenceError, instenceSuccess } = useGetInstence(
    getPage,
    params.id,
    (response) => {
      const {title, pageToken} = response.data.data.Page
      setEditedPage({
        title,
        pageToken
      });
    }
  );

  const { setData, loading, error, success } = useResponse(editPage, () => {
    navigate.push("/pages");
  });

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      ...INITIAL_VALS,
      ...editedPage,
    },
    onSubmit: (values) => {
      const formData = handleFormatFormData(values);
      setData({ type: "edit", id: params.id, data: formData });
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={"Edit Page"} />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  {editedPage && (
                    <Form
                      className="needs-validation"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <PageForm validation={validation} />
                      <ResultHandler
                        loading={instenceLoading}
                        error={error}
                        success={success}
                        successMsg={"Updated Successfully!"}
                      />
                      <FormButtonHandler btnTxt="Save" loading={loading} />
                    </Form>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditPage;
