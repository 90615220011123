import React, { useState } from "react";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import DeleteModal from "./Common/DeleteModal";
import { useResponse } from "../hooks/useResponse";
import i18next from "i18next";

export default function TableAction({
  id,
  hasEdit,
  hasDelete,
  onDeleteClick,
  onEditClick,
  editUrl,
  deleteApi,
  onDeleteSuccess,
  outline
}) {
  const navigate = useHistory();

  //// delete
  const [show, setShow] = useState(false);

  function onCloseClick() {
    setShow(false);
  }

  const { setData } = useResponse(deleteApi, () => {
    setShow(false);
    onDeleteSuccess();
  });

  function handleDeleteConfirm() {
    setData({ type: "add", data: id });
  }

  return (
    <>
      {hasEdit && (
        // localStorage.getItem("permissions").includes(editPermission) &&
        <Button
          color="primary"
          onClick={() => {
            if (onEditClick) {
              onEditClick();
            } else {
              navigate.push(editUrl);
            }
          }}
          outline={outline}
          style={{...(i18next.language == 'en' ?{ marginRight:  "5px" } : {marginLeft: "5px"}), ...(outline ? {border: "0px"} : {})}}
        >
          <i className="bx bx-edit font-size-16 align-middle" />
        </Button>
      )}

      {
        // localStorage.getItem("permissions").includes(deletePermission) &&
        hasDelete && (
          <Button
            color="danger"
            outline={outline}
            style={outline ? {border: "0px"} : {}}
            onClick={() => {
              if (onDeleteClick) {
                onDeleteClick();
              } else {
                setShow(true);
              }
            }}
          >
            <i className="bx bx-trash font-size-16 align-middle" />
          </Button>
        )
      }

      <DeleteModal
        show={show}
        onDeleteClick={handleDeleteConfirm}
        onCloseClick={onCloseClick}
      />
    </>
  );
}
