import { formatValidationErr, handleMultilangFields } from "../../../hooks/utils";


export const FORM_FIELDS = [
    {key: "name", label: "Name", isMultilang: true },
]

export const INITIAL_VALS = {
  name: "",

}

export function handleFormatFormData(vals, lang){
  
 return handleMultilangFields({values: vals, fields: FORM_FIELDS, lang})
}