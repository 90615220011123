import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
  InputGroup,
  InputGroupText,
} from "reactstrap";

//redux

import { Link, useHistory } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { useRecoilState } from "recoil";
import authState from "../atom/auth";
import { login } from "../api/auth";
import ResultHandler from "../components/ResultHandler";
import logo from "../assets/images/logo.png";
import useCustomValidation from "./dashboard/validationSchema";
import { useTranslation } from "react-i18next";
import "./Login.css";

const Login = () => {
  const { t } = useTranslation();
  const { loginSchema } = useCustomValidation();
  //meta title
  const [isLogged, setIsLogged] = useRecoilState(authState);
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      email: localStorage.getItem("email"),
      password: localStorage.getItem("password"),
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      setLoading(true);
      let email = values.email;
      let password = values.password;
      login({ email, password })
        .then(({ data }) => {
          localStorage.setItem("token", data.data.token);
          localStorage.setItem("user", JSON.stringify(data.data.admin));
          localStorage.setItem(
            "permissions",
            JSON.stringify(data.data.admin.role.Permissions.map((p) => p.token))
          );

          setIsLogged(true);
          setLoading(false);

          if(data.data.admin.role.Permissions.map((p) => p.token).includes('permission-slide-view')){
            history.push("/home-slides");

          }else{
            history.push("/careers");

          }
        })
        .catch((err) => {
          console.log(err);
          setError(err.response.data.message);
          setLoading(false);
        });
    },
  });

  return (
    <div className="auth-layout pt-5">
      <div className="account-pages pt-5">
        <Container className="pt-5">
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className=" login-box">
                <CardBody className="pt-4">
                  <img src={logo} alt="" />

                  <div className="pt-4 mt-4 form">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <h2 className="text-center mb-4">Login</h2>
                      <div className="mb-3">
                        <InputGroup>
                          <InputGroupText>
                            <i className="mdi mdi-account-outline"></i>
                          </InputGroupText>
                          <Input
                            name="email"
                            placeholder="Enter Email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                        </InputGroup>
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div style={{ marginBottom: "30px" }}>
                        <InputGroup>
                          <InputGroupText>
                            <i className="mdi mdi-lock-outline"></i>
                          </InputGroupText>
                          <Input
                            name="password"
                            value={validation.values.password || ""}
                            type="password"
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />
                        </InputGroup>
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="d-grid">
                        <ResultHandler error={error} />
                        {loading && (
                          <Button size="lg" type="button">
                            <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>{" "}
                            {t("login.loading")}
                          </Button>
                        )}
                        {!loading && (
                          <Button color="primary" size="lg" type="submit">
                            {t("login.login")}
                          </Button>
                        )}
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Login;
