import React, { useEffect } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap";
// Formik validation
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useHistory } from "react-router-dom";
import ResultHandler from "../../../components/ResultHandler";
import ChooseImage from "../../../components/ChooseImage";
import FormButtonHandler from "../../../components/FormButtonHandler";
import { useResponse } from "../../../hooks/useResponse";
import SectionForm from "./SectionForm";
import {
  handleFormatFormData,
  INITIAL_VALS,
  VALIDATION_SCHEMA,
} from "./CONSTANT";
import { addSection } from "../../../api/sections";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import langState from "../../../atom/lang";

const AddSection = () => {
  let navigate = useHistory();
  const { pageId } = useParams();

  const [lang] = useRecoilState(langState)

  const { setData, loading, error, success } = useResponse(addSection, () => {
    navigate.goBack();
  });

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: INITIAL_VALS,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: (values) => {

      const formData = handleFormatFormData({ ...values, pageToken: pageId }, lang);
      setData({ data: formData, type: "add" });
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Card>
            <CardBody>
              <Breadcrumbs title={"Add Section"} />
              <Row>
                <Col xl="12">
                  <Form
                    className="needs-validation"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <SectionForm validation={validation} />
                    <ResultHandler
                      error={error}
                      success={success}
                      successMsg={"Created Successfully!"}
                    />
                    <FormButtonHandler btnTxt="Add" loading={loading} />
                  </Form>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddSection;
