import { myAxios } from "./axios"

export function getDevelopers() {
  
    return myAxios.get("/developers")
}


export function getDeveloper(id) {
    return myAxios.get("/developers/" + id)
}

export function addDeveloper({data}) {
    return myAxios.post("/developers/create", data)
}

export function editDeveloper({data, id}) {
    return myAxios.put("/developers/" + id + "/update", data)
}

export function deleteDeveloper(id) {
    return myAxios.delete("/developers/" + id + "/delete")
}
