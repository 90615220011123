import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useEffect, useState } from "react";
import draftToHtml from "draftjs-to-html";
import { useResponse } from "../hooks/useResponse";
import { uploadImage } from "../api/posts";
import ResultHandler from "./ResultHandler";
import { Button, Row, Col, Label } from "reactstrap";
import "./HtmlEditor.css";
function ImagePicker({ onChange }) {
  const [img, setImg] = useState(null);

  const [dims, setDims] = useState({
    width: "auto",
    height: "auto",
  });

  const [open, setOpen] = useState(null);

  const { loading, error, setData } = useResponse(uploadImage, (res) => {
    const url = res.data.image;

    setOpen(false);
    onChange(url, dims.height, dims.width);
  });

  function handleSubmit() {
    const formData = new FormData();

    formData.append("image", img);

    setData({ type: "add", data: formData });
  }

  useEffect(() => {
    setDims({
      width: "auto",
      height: "auto",
    });
    setImg(null);
  }, [open]);

  return (
    <div style={{ position: "relative" }}>
      <span onClick={() => setOpen(!open)}>
        <img
          src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iIzAwMCIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTQuNzQxIDBILjI2Qy4xMTYgMCAwIC4xMzYgMCAuMzA0djEzLjM5MmMwIC4xNjguMTE2LjMwNC4yNTkuMzA0SDE0Ljc0Yy4xNDMgMCAuMjU5LS4xMzYuMjU5LS4zMDRWLjMwNEMxNSAuMTM2IDE0Ljg4NCAwIDE0Ljc0MSAwem0tLjI1OCAxMy4zOTFILjUxN1YuNjFoMTMuOTY2VjEzLjM5eiIvPjxwYXRoIGQ9Ik00LjEzOCA2LjczOGMuNzk0IDAgMS40NC0uNzYgMS40NC0xLjY5NXMtLjY0Ni0xLjY5NS0xLjQ0LTEuNjk1Yy0uNzk0IDAtMS40NC43Ni0xLjQ0IDEuNjk1IDAgLjkzNC42NDYgMS42OTUgMS40NCAxLjY5NXptMC0yLjc4MWMuNTA5IDAgLjkyMy40ODcuOTIzIDEuMDg2IDAgLjU5OC0uNDE0IDEuMDg2LS45MjMgMS4wODYtLjUwOSAwLS45MjMtLjQ4Ny0uOTIzLTEuMDg2IDAtLjU5OS40MTQtMS4wODYuOTIzLTEuMDg2ek0xLjgxIDEyLjE3NGMuMDYgMCAuMTIyLS4wMjUuMTcxLS4wNzZMNi4yIDcuNzI4bDIuNjY0IDMuMTM0YS4yMzIuMjMyIDAgMCAwIC4zNjYgMCAuMzQzLjM0MyAwIDAgMCAwLS40M0w3Ljk4NyA4Ljk2OWwyLjM3NC0zLjA2IDIuOTEyIDMuMTQyYy4xMDYuMTEzLjI3LjEwNS4zNjYtLjAyYS4zNDMuMzQzIDAgMCAwLS4wMTYtLjQzbC0zLjEwNC0zLjM0N2EuMjQ0LjI0NCAwIDAgMC0uMTg2LS4wOC4yNDUuMjQ1IDAgMCAwLS4xOC4xTDcuNjIyIDguNTM3IDYuMzk0IDcuMDk0YS4yMzIuMjMyIDAgMCAwLS4zNTQtLjAxM2wtNC40IDQuNTZhLjM0My4zNDMgMCAwIDAtLjAyNC40My4yNDMuMjQzIDAgMCAwIC4xOTQuMTAzeiIvPjwvZz48L3N2Zz4="
          alt=""
        ></img>
      </span>

      {open && (
        <div className="picker">
          <input
            accept=".png,.jpg,.jpeg"
            type="file"
            onChange={(e) => setImg(e.target.files[0])}
          />

          {img && (
            <div className="mt-3">
              {!loading && (
                <>
                  <div style={{ position: "relative" }}>
                    <img
                      src={URL.createObjectURL(img)}
                      style={{
                        width: "100%",
                        height: "200px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                  <Row className="mt-2">
                    <Col md={6}>
                      <label>Width</label>
                      <input
                        className="form-control"
                        type="text"
                        value={dims.width}
                        onChange={(e) =>
                          setDims((d) => ({ ...d, width: e.target.value }))
                        }
                      />
                    </Col>
                    <Col md={6}>
                      <label>Height</label>
                      <input
                        className="form-control"
                        type="text"
                        value={dims.height}
                        onChange={(e) =>
                          setDims((d) => ({ ...d, height: e.target.value }))
                        }
                      />
                    </Col>
                  </Row>
                </>
              )}
              <ResultHandler loading={loading} error={error} />
              {!loading && (
                <div className="mt-3">
                  <Button color="primary" onClick={handleSubmit}>
                    Confirm
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

function LinkPicker({onChange}) {
  const [open, setOpen] = useState(null);

  const [data, setData] = useState({
    url: "",
    title: "",
    openInNewtab: false,
    isPdf: true,
  });

  function handleInsert() {
    onChange("link", data.title, data.url+(data.isPdf ? "?isPdf=true": ""), data.openInNewtab)
    setOpen(false)
  }

  return (
    <div style={{ position: "relative" }}>
      <span onClick={() => setOpen(!open)}>
        <img
          src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEzLjk2Ny45NUEzLjIyNiAzLjIyNiAwIDAgMCAxMS42Ny4wMDJjLS44NyAwLTEuNjg2LjMzNy0yLjI5Ny45NDhMNy4xMDUgMy4yMThBMy4yNDcgMy4yNDcgMCAwIDAgNi4yNCA2LjI0YTMuMjI1IDMuMjI1IDAgMCAwLTMuMDIyLjg2NUwuOTUgOS4zNzNhMy4yNTMgMy4yNTMgMCAwIDAgMCA0LjU5NCAzLjIyNiAzLjIyNiAwIDAgMCAyLjI5Ny45NDhjLjg3IDAgMS42ODYtLjMzNiAyLjI5OC0uOTQ4TDcuODEyIDExLjdhMy4yNDcgMy4yNDcgMCAwIDAgLjg2NS0zLjAyMyAzLjIyNSAzLjIyNSAwIDAgMCAzLjAyMi0uODY1bDIuMjY4LTIuMjY3YTMuMjUyIDMuMjUyIDAgMCAwIDAtNC41OTV6TTcuMTA1IDEwLjk5M0w0LjgzNyAxMy4yNmEyLjIzMyAyLjIzMyAwIDAgMS0xLjU5LjY1NSAyLjIzMyAyLjIzMyAwIDAgMS0xLjU5LS42NTUgMi4yNTIgMi4yNTIgMCAwIDEgMC0zLjE4bDIuMjY4LTIuMjY4YTIuMjMyIDIuMjMyIDAgMCAxIDEuNTktLjY1NWMuNDMgMCAuODQxLjEyIDEuMTk1LjM0M0w0Ljc3MiA5LjQzOGEuNS41IDAgMSAwIC43MDcuNzA3bDEuOTM5LTEuOTM4Yy41NDUuODY4LjQ0MiAyLjAzLS4zMTMgMi43ODV6bTYuMTU1LTYuMTU1bC0yLjI2OCAyLjI2N2EyLjIzMyAyLjIzMyAwIDAgMS0xLjU5LjY1NWMtLjQzMSAwLS44NDEtLjEyLTEuMTk1LS4zNDNsMS45MzgtMS45MzhhLjUuNSAwIDEgMC0uNzA3LS43MDdMNy40OTkgNi43MWEyLjI1MiAyLjI1MiAwIDAgMSAuMzEzLTIuNzg1bDIuMjY3LTIuMjY4YTIuMjMzIDIuMjMzIDAgMCAxIDEuNTktLjY1NSAyLjIzMyAyLjIzMyAwIDAgMSAyLjI0NiAyLjI0NWMwIC42MDMtLjIzMiAxLjE2OC0uNjU1IDEuNTl6IiBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4="
          alt=""
        />
      </span>

      {open && (
        <div className="picker" style={{ right: "0px", left: "initial" }}>
          <div className="mb-3">
            <Label>Link Url</Label>
            <input
              style={{ height: "40px" }}
              className="form-control"
              type="text"
              onChange={(e) => setData((d) => ({ ...d, url: e.target.value }))}
            />
          </div>
          <div className="mb-3">
            <Label>Link Title</Label>
            <input
              style={{ height: "40px" }}
              className="form-control"
              type="text"
              onChange={(e) =>
                setData((d) => ({ ...d, title: e.target.value }))
              }
            />
          </div>

          <div>
            <input
              type="checkbox"
              onChange={() =>
                setData((d) => ({ ...d, openInNewtab: !d.openInNewtab }))
              }
            />{" "}
            Open in new tab
          </div>

          <div className="mb-3">
            <input
              type="checkbox"
              onChange={() => setData((d) => ({ ...d, isPdf: !d.isPdf }))}
            />{" "}
            Pdf file
          </div>

          <Button color="primary" onClick={handleInsert}>
            Enter
          </Button>
        </div>
      )}
    </div>
  );
}

export default function HtmlEditor({
  defaultValue,
  onChange,
  allowImg = false,
}) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  function setDefaultContent() {
    /// default is raw
    setEditorState(EditorState.createWithContent(convertFromRaw(defaultValue)));
  }

  function handleChange(e) {
    const raw = convertToRaw(e.getCurrentContent()); /// save raw
    onChange(raw);
    const html = draftToHtml(raw);
    setEditorState(e);
  }

  useEffect(() => {
    if (defaultValue) {
      setDefaultContent();
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, [defaultValue]);

  return (
    <Editor
      toolbar={{
        image: { component: ImagePicker },
        link: { component: LinkPicker },

        options: [
          "inline",
          "blockType",
          "list",
          "textAlign",
          "link",
          ...(allowImg ? ["image"] : []),
          "history",
        ],
      }}
      placeholder="Type something"
      editorState={editorState}
      onEditorStateChange={handleChange}
    />
  );
}
