import NonAuthLayout from "../components/NonAuthLayout"
import React from "react"
import { Route, Redirect } from "react-router-dom"
import Login from "../pages/Login"

function redirect_to_home (){
  const Permissions = JSON.parse(localStorage.getItem("user")).role.Permissions
  if(Permissions.map((p) => p.token).includes('permission-slide-view')){
    return (
      <Redirect
        to={{ pathname: "/home-slides"}}
      />
    )

  }else{
    return (
      <Redirect
        to={{ pathname: "/careers" }}
      />
    )

  }
}

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (localStorage.getItem("user")) {
        if (isAuthProtected) {
            return (
              <Layout>
                <Component {...props} />
              </Layout>
            )
         
        } else {
          return redirect_to_home()
        }
      } else {
        if (props.location.pathname != "/login") {
          localStorage.removeItem('token')
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
      }else{
        return(
          <NonAuthLayout>
          <Login />
        </NonAuthLayout>
        )
      }
      }
    }}
  />
)

export default Authmiddleware
