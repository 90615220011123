import { myAxios } from "./axios"

export function getMembers(data, { page, perPage }) {
    let config = {
        params: {
            page,
            perPage,
            filter: data

        },
    }
    return myAxios.get("/members", config)
}

export function getMember(id) {
    return myAxios.get("/members/" + id)
}

export function addMember(data) {
    return myAxios.post("/members/create", data)
}

export function editMember(id, data) {
    return myAxios.put("/members/" + id + "/update", data)
}

export function deleteMember(id) {
    return myAxios.delete("/members/" + id + "/delete")
}
