import { myAxios } from "./axios"

export function getPosts(data, { page, perPage }) {
    let config = {
        params: {
            page,
            perPage,
            filter: data

        },
    }
    return myAxios.get("/posts", config)
}

export function getPost(id) {
    return myAxios.get("/posts/" + id)
}

export function addPost(data) {
    return myAxios.post("/posts/create", data)
}

export function editPost(id, data) {
    return myAxios.put("/posts/" + id + "/update", data)
}

export function deletePost(id) {
    return myAxios.delete("/posts/" + id + "/delete")
}
export function uploadImage(data) {
    return myAxios.post("/posts/upload-image",data)
}
