import * as Yup from "yup"
import { formatValidationErr, handleMultilangFields } from "../../../hooks/utils";
import {  getInterestedInOptions } from "../../../api/interestedinOptions";

export const FORM_FIELDS = [
  { key: "title", label: "Title", isMultilang: true },

  { key: "logo", label: "Logo", type: "file", notes: "Size: 520 x 420" },

  { key: "location", label: "Address", isMultilang: true },

  { key: "date", label: "Start Date", type: "date" },
  { key: "endDate", label: "End Date", type: "date" },

  { key: "content", type: "textarea", condition: (values) => new Date(values.endDate) > new Date(), label: "Content", isMultilang: true },
  { key: "banner", label: "Banner", type: "file", condition: (values) => new Date(values.endDate) > new Date(), notes: "Size: 1520 x 620" },
  { key: "desktop_video", notes: "Size: 1920 x 1080 (HD)", label: "Desktop video" , condition: (values) => new Date(values.endDate) > new Date(), type: "file", fileType: "video" },
  { key: "mob_video", notes: "Size: 1080 x 1400px", label: "Mobile Video", condition: (values) => new Date(values.endDate) > new Date(), type: "file", fileType: "video" },

  {key: "interestedIns", label: "Interested in options", condition: (values) => new Date(values.endDate) > new Date(), type: 'select', isMulti: true, api: getInterestedInOptions, apiLabelField: "field"}
];

export const INITIAL_VALS = {
  title: "",
  logo: null,
  banner: null,
  location: "",
  content: "",
  date: null,

  developers: [],
};

export function handleFormatFormData(vals, lang) {
  const values = handleMultilangFields({fields: FORM_FIELDS, values: vals, lang})
  const formData = new FormData();

  Object.keys(values).map((key) => {
    if (values[key]) {
      if(['developers', 'interestedIns'].includes(key) ){
        values[key].map(v => {
          formData.append(key+'[]', v);

        })
      } else {
        formData.append(key, values[key]);
      }
    }
  });

  return formData;
}

export const VALIDATION_SCHEMA = Yup.object().shape({
  title: Yup.string().required(formatValidationErr('Title')),
  location: Yup.string().required(formatValidationErr('Location')),
  date: Yup.string().required(formatValidationErr('Date')).nullable(),
  logo_preview: Yup.string().required(formatValidationErr('Logo')),
  //banner_preview: Yup.string().required(formatValidationErr('Banner')),

})
