import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap";
// Formik validation
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useHistory, useParams } from "react-router-dom";
import ResultHandler from "../../../components/ResultHandler";
import { editSeo, getSeo } from "../../../api/seos";
import FormButtonHandler from "../../../components/FormButtonHandler";
import useCustomValidation from "../validationSchema";
import { useResponse } from "../../../hooks/useResponse";
import { useTranslation } from "react-i18next";
import { useGetInstence } from "../../../hooks/useGetInstence";
import SeoForm from "./SeoForm";
import {
  FORM_FIELDS,
  handleFormatFormData,
  INITIAL_VALS,
  VALIDATION_SCHEMA,
} from "./CONSTANT";
import FormLangSwitcher from "../../../components/FormLangSwitcher";
import langState from "../../../atom/lang";
import { useRecoilState } from "recoil";

const EditSeo = () => {
  let navigate = useHistory();
  const [lang] = useRecoilState(langState)

  const params = useParams();

  const { instenceLoading, instence: editedSeo } = useGetInstence({
    apiMeth: () => getSeo(params.id),
    multilangFields: FORM_FIELDS.filter(f => f.isMultilang).map(f => f.key),
    getInstence: ({response}) => {
      const { title, description, social_title, social_description, social_image } = response;
      return {
        title,
        description,
        social_title,
        social_description,
        social_image: null,
        social_image_preview: social_image
      }
    }
  });

  const { setData, loading, error, success } = useResponse(editSeo, () => {
    navigate.goBack();
  });

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      ...INITIAL_VALS,
      ...editedSeo,
    },
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: (values) => {
      const formData = handleFormatFormData(values, lang);
      setData({ type: "edit", id: params.id, data: formData });
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <div className="d-flex justify-content-between">
            <Breadcrumbs title={"Edit Seo"} />
            <FormLangSwitcher />
          </div>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  {editedSeo && (
                    <Form
                      className="needs-validation"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <SeoForm validation={validation} />
                      <ResultHandler
                        loading={instenceLoading}
                        error={error}
                        success={success}
                        successMsg={"Updated Successfully!"}
                      />
                      <FormButtonHandler btnTxt="Save" loading={loading} />
                    </Form>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditSeo;
