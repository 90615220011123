import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap";

import React, { useEffect } from "react";
import SearchSelectBox from "./SearchSelectBox";
import { useTranslation } from "react-i18next";
import AddMoreInput from "./AddMoreInput";
import AddMoreInputV2 from "./AddMoreInputV2";
import moment from "moment";
import ChooseImage from "./ChooseImage";
import HtmlEditor from "./HtmlEditor";
import { t } from "i18next";

function formatLabel(key) {
  return t(key.split("_").join(" "));
}
export const FieldInput = ({ f, validation, style }) => {
  const { t } = useTranslation();

  if (f.condition && !f.condition(validation.values)) return <></>;

  return (
    <div className="form-group" style={style}>
      {(!f.type ||
        ["number", "date", "time", "textarea", "email"].includes(f.type)) && (
        <Row>
          <Col md={3}>
            <Label>{formatLabel(f.label)}</Label>
            {f.notes && <span className="font-size-12 d-block">{f.notes}</span>}
            {f.hasCounter && <span className="font-size-15 d-block">{validation.values[f.key].length} ( of {f.maxLength})</span>}
            {validation.touched[f.key] && validation.errors[f.key] ? (
              <FormFeedback className="d-block" type="invalid">
                {validation.errors[f.key]}
              </FormFeedback>
            ) : null}
          </Col>
          <Col md={9}>
            <Input
              name={f.key}
              placeholder={formatLabel(f.label)}
              type={f.type || "text"}
              className="form-control"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={
                f.type === "date"
                  ? validation.values[f.key]
                    ? moment(validation.values[f.key]).format("YYYY-MM-DD")
                    : null
                  : validation.values[f.key] !== undefined
                  ? validation.values[f.key]
                  : ""
              }
              invalid={validation.touched[f.key] && validation.errors[f.key]}
            />
          </Col>
        </Row>
      )}
      {f.type == "checkbox" && (
        <div className="d-flex" style={{ alignItems: "center" }}>
          <input
            name={f.key}
            placeholder={formatLabel(f.label)}
            type={"checkbox"}
            onChange={() => {
              if (validation.values[f.key]) {
                validation.setFieldValue(f.key, false);
              } else {
                validation.setFieldValue(f.key, true);
              }
            }}
            checked={validation.values[f.key] || false}
          />
          <Label style={{ margin: "0px 10px" }}>{formatLabel(f.label)}</Label>
        </div>
      )}
      {f.type === "select" && (
        <Row>
          <Col md={3}>
            <Label>{formatLabel(f.label)}</Label>
          </Col>
          <Col md={9}>
            <SearchSelectBox
              name={f.key}
              initialOptions={validation.values[f.key + "Arr"]}
              api={f.api}
              apiMeta={f.apiMeta}
              type={f.apiResKey}
              label={f.apiLabelField || "name"}
              setValue={(e) => {
                if (f.isMulti) {
                  validation.setFieldValue(
                    f.key,
                    e.map((a) => a.value)
                  );
                } else {
                  validation.setFieldValue(f.key, e?.value || "");
                }
              }}
              value={validation.values[f.key] || null}
              error={validation.touched[f.key] && validation.errors[f.key]}
              isMulti={f.isMulti}
              disabled={f.disabled}
            />
          </Col>
        </Row>
      )}
      {f.type == "fixed-select" && (
        <Row>
          <Col md={3}>
            <Label>{formatLabel(f.label)}</Label>
            {validation.touched[f.key] && validation.errors[f.key] ? (
              <div className="invalid-feedback d-block">
                {validation.errors[f.key]}
              </div>
            ) : null}
          </Col>
          <Col md={9}>
            <div className="selectbox-wrapper">
              <select
                name={f.key}
                onChange={validation.handleChange}
                value={validation.values[f.key]}
                className={`form-control ${
                  validation.touched[f.key] && validation.errors[f.key]
                    ? "border-danger"
                    : ""
                }`}
              >
                <option></option>
                {f.options.map((op) => (
                  <option key={op.value} value={op.value}>
                    {op.label}
                  </option>
                ))}
              </select>
            </div>
          </Col>
        </Row>
      )}
      {f.type == "add-more" && (
        <AddMoreInput
          label={t("forms." + f.label)}
          values={validation.values[f.key] || ["New value"]}
          onChangeValues={(vals) => {
            validation.setFieldValue(f.key, vals);
          }}
        />
      )}
      {f.type == "add-more-v2" && (
        <AddMoreInputV2
          label={f.label}
          values={validation.values[f.key] || []}
          onChangeValue={(val, i, key) => {
            validation.setFieldValue(
              f.key,
              validation.values[f.key].map((pair, pairI) => {
                if (pairI == i) {
                  return { ...pair, [key]: val };
                } else {
                  return pair;
                }
              })
            );
          }}
          schema={f.schema}
          handleAddNewValue={() => {
            const data = {};
            Object.keys(f.schema).forEach((k) => {
              data[k] = "";
            });
            validation.setFieldValue(f.key, [
              ...(validation.values[f.key] || []),
              data,
            ]);
          }}
          handleRemovePair={(i) => {
            validation.setFieldValue(
              f.key,
              validation.values[f.key].filter((pair, pairI) => pairI != i)
            );
          }}
        />
      )}

      {f.type == "file" && (
        <>
          <ChooseImage
            error={validation.errors[f.key + "_preview"]}
            handleChooseImage={(file) => {
              validation.setFieldValue(f.key, file);
              if (file) {
                validation.setFieldValue(
                  f.key + "_preview",
                  URL.createObjectURL(file)
                );
              } else {
                validation.setFieldValue(f.key + "_preview", "");
              }
            }}
            notes={f.notes}
            image={validation.values[f.key + "_preview"]}
            label={formatLabel(f.label)}
            fileType={f.fileType || "img"}
          />
        </>
      )}

      {f.type == "html" && (
        <Row>
          <Col md={3}>
            <Label>{formatLabel(f.label)}</Label>
          </Col>
          <Col md={9}>
            <HtmlEditor
              allowImg={f.allowImg}
              defaultValue={validation.values[f.key + "_default"]}
              onChange={(v) => {
                validation.setFieldValue(f.key, JSON.stringify(v));
              }}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};
