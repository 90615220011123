import { myAxios } from "./axios"

export function getNewsletterSubmissions(data, { page, perPage }) {
    let config = {
        params: {
            page,
            perPage,
            ...(data || {})

        },
    }
    return myAxios.get("/newsletters", config)
}


export function exportNewsletterSubmissions(data) {
    let config = {
        params: {
       
            ...(data || {})

        },
    }
    return myAxios.get("/newsletters/export", config)
}

export function deleteNewsletterSubmission(id) {
    return myAxios.delete("/newsletters/" + id + "/delete")
}
