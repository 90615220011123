import { myAxios } from "./axios"

export function getEvents(data, { page, perPage }) {
    let config = {
        params: {
            page,
            perPage,
            ...(data || {})

        },
    }
    return myAxios.get("/events", config)
}


export function getEvent(id) {
    return myAxios.get("/events/" + id)
}

export function getEventGallery(id) {
    return myAxios.get("/events/event-media/" + id)
}
export function addEventMedia(data) {
    return myAxios.post("/media/create",data)
}
export function deleteEventMedia(id) {
    return myAxios.delete("/media/"+id+"/delete")
}
export function markEventMediaFeatured(data) {
    return myAxios.post("/media/make-featured",data)
}

export function addEvent(data) {
    return myAxios.post("/events/create", data)
}

export function editEvent(id, data) {
    return myAxios.put("/events/" + id + "/update", data)
}

export function deleteEvent(id) {
    return myAxios.delete("/events/" + id + "/delete")
}


export function getEventsRegistrations(data, { page, perPage }) {
    let config = {
        params: {
            page,
            perPage,
            
            ...(data || {})

        },
    }
    return myAxios.get("/event-registrations", config)
}
export function exportEventsRegisterations(data) {
    let config = {
        params: {
            ...(data || {})

        },
    }
    return myAxios.get("/event-registrations/export", config)
}

export function deleteEventRegistertion(id) {
    return myAxios.delete("/event-registrations/" + id + "/delete")
}