import { myAxios } from "./axios"


export function getFiles() {
   
    return myAxios.get("/files")
}


export function createFile(data) {
    return myAxios.post("/files/create", data)
}
export function deleteFile(id) {
    return myAxios.delete("/files/"+id+"/delete")
}