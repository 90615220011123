import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap";

import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { FORM_FIELDS } from "./CONSTANT";
import { FieldInput } from "../../../components/FieldInput";

export default function SlideForm({ validation }) {
  return (
    <Row>
      {FORM_FIELDS.map((f) => (
        <FieldInput key={f.key} f={f} validation={validation} />
      ))}
      <FieldInput
        f={{
          key: "mediaType",
          label: "Media type",
          type: "fixed-select",
          options: [{value: "img", label: "Image"}, {value: "video",label: "Video"}],
        }}
        validation={{
          ...validation,
          handleChange: (e) => {
            validation.setFieldValue('mediaType', e.target.value);
            validation.setFieldValue("media", null);
            validation.setFieldValue("media_preview", null);
          },
        }}
      />
      <FieldInput
        f={{
          key: "media",
          type: "file",
          label: "Media file",
          fileType: validation.values.mediaType,
          notes: "size: 1920 x 1080 (HD)"
        }}
        validation={validation}
      />
      <FieldInput
        f={{
          key: "mob_media",
          type: "file",
          label: "Mobile Media file",
          fileType: validation.values.mediaType,
          notes: "size: 1080 x 1400px"
        }}
        validation={validation}
      />
    </Row>
  );
}
