import { myAxios } from "./axios"

export function getInterestedInOptions() {
  
    return myAxios.get("/interestedIn-options")
}

export function getInterestedInOption(id) {
    return myAxios.get("/interestedIn-options/" + id)
}

export function addInterestedInOption(data) {
    return myAxios.post("/interestedIn-options/create", data)
}

export function editInterestedInOption(data) {
    return myAxios.put("/interestedIn-options/" + data.id + "/update", data)
}

export function deleteInterestedInOption(id) {
    return myAxios.delete("/interestedIn-options/" + id + "/delete")
}
