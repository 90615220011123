import Breadcrumbs from "../../../components/Common/Breadcrumb";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { usePagination } from "../../../hooks/usePagination";
import ResultHandler from "../../../components/ResultHandler";
import PaginationHandler from "../../../components/PaginationHandler";
import { deletePage, getPages } from "../../../api/pages";

import TableAction from "../../../components/TableAction";

import { Button } from "reactstrap";
import { PERMISSIONS } from "../../../api/permissions";

import CustomTable from "../../../components/CustomTable/CustomTable";

export default function PagesList() {
  const {
    response,
    loading,
    error,
    setPage,
    page,
    setPerPage,
    totalPages,
    setPayload,
  } = usePagination(getPages);
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs
          isMainPage={true}
          title={"Pages"}
          hasAddBtn={true}
          addUrl={"/add-page"}
        />

        <CustomTable
          emptyMsg={"No Pages Found."}
          data={response?.data?.pages}
          loading={loading}
          error={error}
          cols={[
            { label: "Title", key: "title", style: { width: "25%" } },
            { label: "Token", key: "pageToken", style: { width: "50%" } },

            {
              label: "Action",
              key: "action",
              style: { width: "20%", textAlign: "right" },
              renderer: (row) => {
                return (
                  <>
                    <Link
                      to={"/pages/" + row.id + "/sections"}
                      className="btn btn-dark"
                      style={{ marginRight: "5px" }}
                    >
                      <i className="mdi mdi-eye"></i>
                    </Link>
                    <TableAction
                      hasEdit={true}
                      hasDelete={true}
                      editPermission={PERMISSIONS.CATEGORY_EDIT}
                      deletePermission={PERMISSIONS.CATEGORY_DELETE}
                      editUrl={"/edit-page/" + row.id}
                      id={row.id}
                      deleteApi={deletePage}
                      onDeleteSuccess={() => setPayload({})}
                    />
                  </>
                );
              },
            },
          ]}
        />

        {response.data && !loading && (
          <PaginationHandler
            page={page}
            setPage={setPage}
            totalPages={totalPages}
          />
        )}
      </div>
    </div>
  );
}
