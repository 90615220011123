import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap";
import { useFormik } from "formik";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useHistory, useParams } from "react-router-dom";
import ResultHandler from "../../../components/ResultHandler";
import FormButtonHandler from "../../../components/FormButtonHandler";
import { editAdmin, getAdmin } from "../../../api/admins";
import { getRolesDropdown } from "../../../api/roles";
import SearchSelectBox from "../../../components/SearchSelectBox";
import { useResponse } from "../../../hooks/useResponse";
import { useTranslation } from "react-i18next";
import { useGetInstence } from "../../../hooks/useGetInstence";
import useCustomValidation from "../validationSchema";
import ChangePasswordModal from "./ChangePasswordModal";

const EditAdmin = () => {
  const { t } = useTranslation();
  const { editAdminSchema } = useCustomValidation();

  const { id: adminId } = useParams();

  const { instenceLoading, instence: editedAdmin } = useGetInstence({
    apiMeth: () => getAdmin(adminId),
    multilangFields: [],
    getInstence: ({ admin }) => {
      return {
        ...admin,
        Role: admin.role
          ? {
              value: admin.role.id,
              label: admin.role.name,
            }
          : null,
      };
    },
  });

  //posting

  const { setData, loading, error, success } = useResponse(editAdmin);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      username: editedAdmin ? editedAdmin.username : "",
      email: editedAdmin ? editedAdmin.email : "",
      // categories: editedAdmin ? editedAdmin.Categories : null,
      role: editedAdmin ? editedAdmin.Role : null,
      password: undefined,
      // language: editedAdmin ? editedAdmin.language : "ar",
    },
    validationSchema: editAdminSchema,
    onSubmit: (values) => {
      let data = {
        ...values,
        roleId: values.role.value,
        // Categories: values.categories
        //   ? values.categories.map(c => c.value)
        //   : null,
      };
      delete data.email;
      setData({ id: adminId, data, type: "edit" });
    },
  });

  let navigate = useHistory();

  useEffect(() => {
    if (success) {
      navigate.push("/admins");
    }
  }, [success]);

  /// change pass

  const [changePassopen, setChangePassopen] = useState(false);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={t("btns.Edit") + " " + t("pages.Admin")} />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <ResultHandler loading={instenceLoading} />
                  {editedAdmin && (
                    <Form
                      className="needs-validation"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <Row>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom01">
                              {t("Name")}
                            </Label>
                            <Input
                              name="username"
                              placeholder={t("Name")}
                              type="text"
                              className="form-control"
                              id="validationCustom01"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.username || ""}
                              invalid={
                                validation.touched.username &&
                                validation.errors.username
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.username &&
                            validation.errors.username ? (
                              <FormFeedback type="invalid">
                                {validation.errors.username}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom03">
                              {t("pages.Role")}
                            </Label>
                            <SearchSelectBox
                              name="role"
                              error={
                                validation.touched.role &&
                                validation.errors.role
                              }
                              isMulti={false}
                              value={validation.values.role?.value || null}
                              type="roles"
                              label="name"
                              api={getRolesDropdown}
                              handleFocus={() => {
                                validation.setFieldTouched("role");
                              }}
                              handleBlur={validation.handleBlur}
                              setValue={(option) => {
                                validation.setFieldValue("role", option);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom02">
                              {t("forms.Email")}
                            </Label>
                            <Input
                              disabled
                              name="email"
                              placeholder={t("forms.Email")}
                              type="email"
                              className="form-control"
                              id="validationCustom02"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup className="mb-3 ">
                            <Label htmlFor="validationCustom03">
                              {t("forms.Password")}
                            </Label>
                            <a
                              className="d-block text-primary"
                              onClick={() => setChangePassopen(true)}
                            >
                              Change password
                            </a>
                          </FormGroup>
                        </Col>
                      </Row>

                      <FormButtonHandler btnTxt="Save" loading={loading} />

                      <ResultHandler
                        error={error}
                        success={success}
                        successMsg={"Updated Successfully!"}
                      />
                    </Form>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <ChangePasswordModal
        isShow={changePassopen}
        handleClose={() => setChangePassopen(false)}
      />
    </React.Fragment>
  );
};

export default EditAdmin;
