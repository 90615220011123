import { Button } from "reactstrap";
import { useResponse } from "../hooks/useResponse";
import { exportEventsRegisterations } from "../api/events";
import { exportCsv } from "../hooks/utils";
import { t } from "i18next";

export function ExportBtn({api, apiMeta}) {
  const { loading, setData } = useResponse(api, (res) => {
    exportCsv(res)
  });

  if(loading) return <Button>{t("Wait")}</Button>

  return (
    <Button className="mb-3" size="lg" onClick={() => setData({type: "add", data: {ref: true, ...(apiMeta || {})}})} color="primary">
      {t("Export")}
    </Button>
  );
}
