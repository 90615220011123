import * as Yup from "yup";
import { formatValidationErr, handleMultilangFields } from "../../../hooks/utils";

export const FORM_FIELDS = [
  { key: "name", label: "Name", isMultilang: true },
  { key: "icon", label: "Icon", type: "file", notes: "Size: 100 x 100" },
  { key: "banner", label: "Banner", type: "file", notes: "Size: 1520 x 330" },
  { key: "description", label: "Description", type: "textarea", isMultilang: true },
  { key: "order", label: "Order", type: "number", notes: "The less order the higher priority" },

];

export function handleFormatFormData(vals, lang) {
  const values = handleMultilangFields({fields: FORM_FIELDS, values: vals, lang})

  const formData = new FormData();

  Object.keys(values).map((key) => {
    if (values[key]) {
     
        formData.append(key, values[key]);
      
    }
  });

  return formData;
}

export const VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().required(formatValidationErr("Name")),
  description: Yup.string().required(formatValidationErr("Description")),
  icon_preview: Yup.string().required(formatValidationErr("Icon")),
  banner_preview: Yup.string().required(formatValidationErr("Banner")),
});
