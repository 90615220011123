export const PERMISSIONS = {
  ADMIN_VIEW: 'permission-admin-view',
  ADMIN_EDIT: 'permission-admin-edit',
  ADMIN_DELETE: 'permission-admin-delete',
  ADMIN_CREATE: 'permission-admin-create',

  ASSET_VIEW: 'permission-assets-view',
  ASSET_EDIT: 'permission-assets-edit',
  ASSET_DELETE: 'permission-assets-delete',
  ASSET_CREATE: 'permission-assets-create',
  ASSET_IMPORT: 'permission-assets-import',

  ROLE_VIEW: 'permission-role-view',
  ROLE_EDIT: 'permission-role-edit',
  ROLE_DELETE: 'permission-role-delete',
  ROLE_CREATE: 'permission-role-create',

  CHANNEL_VIEW: 'permission-channels-view',
  CHANNEL_EDIT: 'permission-channels-edit',
  CHANNEL_DELETE: 'permission-channels-delete',
  CHANNEL_CREATE: 'permission-channels-create',

  CATEGORY_VIEW: 'permission-category-view',
  CATEGORY_EDIT: 'permission-category-edit',
  CATEGORY_DELETE: 'permission-category-delete',
  CATEGORY_CREATE: 'permission-category-create',

  GENRE_VIEW: 'permission-genres-view',
  GENRES_EDIT: 'permission-genres-edit',
  GENRES_DELETE: 'permission-genres-delete',
  GENRES_CREATE: 'permission-genres-create',

  SUPPLIER_VIEW: 'permission-suppliers-view',
  SUPPLIER_EDIT: 'permission-suppliers-edit',
  SUPPLIER_DELETE: 'permission-suppliers-delete',
  SUPPLIER_CREATE: 'permission-suppliers-create',

  LANGUAGE_VIEW: 'permission-language-view',
  LANGUAGES_EDIT: 'permission-language-edit',
  LANGUAGES_DELETE: 'permission-language-delete',
  LANGUAGES_CREATE: 'permission-language-create',


  AUDITS_VIEW: 'permission-audits-view',
  AUDIT_REVERT: 'permission-audits-revert'
}
