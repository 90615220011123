import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Row, Col, BreadcrumbItem, Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import i18next, { t } from "i18next";

const Breadcrumb = ({
  title,
  breadcrumbItem,
  hasAddBtn,
  addTxt,
  addUrl,
  onAddBtnClick,
  isMainPage,
}) => {
  const navigate = useHistory();

  return (
    <Row>
      <Col xs="12">
        <div className="page-title d-sm-flex mb-4 align-items-center justify-content-between">
          <h4 className="mb-0 d-flex align-items-center">
            {!isMainPage && (
              <>
              {i18next.language == 'en' && <i
                className="bx bx-chevron-left"
                onClick={() => navigate.goBack()}
                style={{
                  fontSize: "30px",
                  cursor: "pointer",
                  marginRight: "5px",
                }}
              ></i>}
               {i18next.language == 'ar' && <i
                className="bx bx-chevron-right"
                onClick={() => navigate.goBack()}
                style={{
                  fontSize: "30px",
                  cursor: "pointer",
                  marginLeft: "5px",
                }}
              ></i>}
              </>
            )}
            {t(title) || t(breadcrumbItem)}
          </h4>
          {hasAddBtn && (
            <Button
              type="button"
              color="primary"
              size="lg"
              onClick={() => {
                if (onAddBtnClick) {
                  onAddBtnClick();
                } else {
                  navigate.push(addUrl);
                }
              }}
            >
              <i className="mdi mdi-plus me-1" />
              {t(addTxt) || t("Create new")}
            </Button>
          )}
        </div>
      </Col>
    </Row>
  );
};

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
};

export default Breadcrumb;
