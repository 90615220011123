import { useEffect, useState } from "react";
import { FieldInput } from "./FieldInput";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useRecoilState } from "recoil";
import langState from "../atom/lang";
import arFlag from '../assets/images/Flag-Egypt.png'
import enFlag from '../assets/images/Flag-Britain.png'
import { useLocation } from "react-router-dom";
import i18next from "i18next";

export default function FormLangSwitcher({}) {
  const [lang, setLang] = useRecoilState(langState);

  const {pathname} = useLocation()

  useEffect(() => {
    setLang(i18next.language)
  }, [pathname])

  return (
    <div className="mb-4">
      {lang == "en" && (
        <Button size="lg" color="primary" onClick={() => setLang("ar")}>
          <img
            src={arFlag}
            style={{ width: "20px", marginRight: "10px" }}
          />
          Translate to Arabic
        </Button>
      )}
      {lang == "ar" && (
        <Button size="lg" color="primary" onClick={() => setLang("en")}>
            <img
            src={enFlag}
            style={{ width: "20px", marginRight: "10px" }}
          />
          Translate to English
        </Button>
      )}
    </div>
  );
}
