import { myAxios } from "./axios"

export function getInTouchSubmissions(data, { page, perPage }) {
    let config = {
        params: {
            page,
            perPage,
            ...(data || {})
        },
    }
    return myAxios.get("/inTouch", config)
}


export function exportInTouchSubmissions(data) {
    let config = {
        params: {
       
            ...(data || {})
        },
    }
    return myAxios.get("/inTouch/export", config)
}

export function deleteInTouchSubmission(id) {
    return myAxios.delete("/inTouch/" + id + "/delete")
}
