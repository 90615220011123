import { getDepartments } from "../../../api/departments";
import * as Yup from "yup";
import {
  formatValidationErr,
  handleMultilangFields,
} from "../../../hooks/utils";
import { getLocations } from "../../../api/locations";

export const FORM_FIELDS = [
  { key: "name", label: "Name", isMultilang: true },
  {
    key: "workType",
    label: "Type",
    type: "fixed-select",
    options: ["full-time", "part-time"].map((v) => ({ value: v, label: v })),
  },

  {
    key: "deptId",
    label: "Department",
    type: "select",
    api: getDepartments,
    apiResKey: "department",
  },
  {
    key: "locationId",
    label: "location",
    type: "select",
    api: getLocations,
    apiResKey: "locations",
  },
  {
    key: "description",
    type: "html",
    isMultilang: true,
    label: "description",
  },
];

export const INITIAL_VALS = {
  name: "",
  workType: "",
  locationId: null,
  description: "",
  deptId: null,
};

export function handleFormatFormData(vals, lang) {
  const values = handleMultilangFields({
    values: vals,
    lang,
    fields: FORM_FIELDS,
  });
  delete values.id;

  return values;
}

export const VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().required(formatValidationErr("Name")),
  workType: Yup.string().required(formatValidationErr("Work Type")),
  deptId: Yup.number().nullable().required(formatValidationErr("Department")),
  description: Yup.string().required(formatValidationErr("Description")),
  locationId: Yup.number().required(formatValidationErr("Location")),
});
