import i18next from "i18next";
import App from "./App";
import React from "react";


export default  function IndexContent(){
    
    i18next.on('languageChanged', (lng) => {
        //console.log(22222222, lng)
        document.location.reload()
    })

    return(
        <App  />
    )
}