import { getDepartments } from "../../../api/departments";

export const FORM_FIELDS = [{ key: "file", label: "File", type: "file" }];

export const INITIAL_VALS = {
  url: null,
};

export function handleFormatFormData(values) {
  const formData = new FormData();

  formData.append("file", values.file);

  return formData;
}
