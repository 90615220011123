import { myAxios } from "./axios"

export function getLocations() {
 
    return myAxios.get("/locations")
}

export function getLocation(id) {
    return myAxios.get("/locations/" + id)
}

export function addLocation(data) {
    return myAxios.post("/locations/create", data)
}

export function editLocation(data) {
    return myAxios.put("/locations/" + data.id + "/update", data)
}

export function deleteLocation(id) {
    return myAxios.delete("/locations/" + id + "/delete")
}
