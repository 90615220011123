import * as Yup from "yup";
import { formatValidationErr, handleMultilangFields } from "../../../hooks/utils";

export const FORM_FIELDS = [
  { key: "title", label: "Title", isMultilang: true },
  { key: "content", label: "Content", type: "html", allowImg: true, isMultilang: true },

  { key: "media", label: "Media", type: "file" },
  { key: "background", label: "Background", type: "file", fileType: "img" },

];

export const INITIAL_VALS = {
  title: "",
  type: "",
};

export function handleFormatFormData(vals, lang) {
  const values = handleMultilangFields({values: vals, lang, fields: FORM_FIELDS})
  const formData = new FormData();

  Object.keys(values).map((key) => {
    if (values[key]) {
      formData.append(key, values[key]);
    }
  });
  return formData;
}


export const VALIDATION_SCHEMA = Yup.object().shape({

});
