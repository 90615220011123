import React from "react";
import { UncontrolledAlert } from "reactstrap";
import { useTranslation } from "react-i18next";
import "./Loader.css";
import loader from '../assets/images/loader.png'
export default function ResultHandler(props) {
  const { t } = useTranslation();

  return (
    <>
      {props.loading && (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <div id="logocontainer">
          <img id="pelogo" src={loader} />
          <div className="loader"></div>
            <div className="loader"></div>
            <div className="loader"></div>
            <div className="loader"></div>
          </div>
        </div>
      )}
      {props.error && (
        <div style={{ marginTop: "20px" }}>
          <UncontrolledAlert color="danger" className="fade show" role="alert">
            <i className="mdi mdi-block-helper me-2"></i>
            {props.error}
          </UncontrolledAlert>
        </div>
      )}
      {props.success && (
        <div style={{ marginTop: "20px" }}>
          <UncontrolledAlert color="success" className="fade show" role="alert">
            {props.successMsg ? props.successMsg : t("success")}
          </UncontrolledAlert>
        </div>
      )}
    </>
  );
}
