import { t } from "i18next";
import { Input, Label } from "reactstrap";

export default function DateFilter({ value, onChange }) {
  return (
    <>
      <div className="d-flex align-items-center" style={{ gap: 10 }}>
        <Label>{t("From")}</Label>
        <Input onChange={(e) => onChange("from", e.target.value)} type="date" />
      </div>
      <div className="d-flex align-items-center" style={{ gap: 10 }}>
        <Label>{t("To")}</Label>
        <Input onChange={(e) => onChange("to", e.target.value)} type="date" />
      </div>
    </>
  );
}
