import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap";
// Formik validation
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useHistory, useParams } from "react-router-dom";
import ResultHandler from "../../../components/ResultHandler";
import { editCareer, getCareer } from "../../../api/careers";
import FormButtonHandler from "../../../components/FormButtonHandler";
import useCustomValidation from "../validationSchema";
import { useResponse } from "../../../hooks/useResponse";
import { useTranslation } from "react-i18next";
import { useGetInstence } from "../../../hooks/useGetInstence";
import CareerForm from "./CareerForm";
import {
  FORM_FIELDS,
  handleFormatFormData,
  INITIAL_VALS,
  VALIDATION_SCHEMA,
} from "./CONSTANT";
import { useRecoilState } from "recoil";
import langState from "../../../atom/lang";
import FormLangSwitcher from "../../../components/FormLangSwitcher";

const EditCareer = () => {
  let navigate = useHistory();

  const [lang] = useRecoilState(langState);
  const params = useParams();

  const { instenceLoading, instence: editedCareer } = useGetInstence({
    apiMeth: () => getCareer(params.id),
    multilangFields: ['description_default', ...FORM_FIELDS.filter((f) => f.isMultilang).map((f) => f.key)],
    getInstence: ({ Career }) => {
      return {
        ...Career,
        description_default: {
          en: Career.description.en ? JSON.parse(Career.description.en) : null,
          ar: Career.description.ar ? JSON.parse(Career.description.ar) : null,

        }
      };
    },
  });

  const { setData, loading, error, success } = useResponse(editCareer, () => {
    navigate.push("/careers");
  });

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      ...INITIAL_VALS,
      ...editedCareer,
    },
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: (values) => {
      const formData = handleFormatFormData(values, lang);
      setData({ type: "edit", id: params.id, data: formData });
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <div className="d-flex" style={{ justifyContent: "space-between" }}>
            <Breadcrumbs breadcrumbItem={"Edit Job Post"} />
            <FormLangSwitcher />
          </div>

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  {editedCareer && (
                    <Form
                      className="needs-validation"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <CareerForm validation={validation} />
                      <ResultHandler
                        loading={instenceLoading}
                        error={error}
                        success={success}
                        successMsg={"Updated Successfully!"}
                      />
                      <FormButtonHandler btnTxt="Save" loading={loading} />
                    </Form>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditCareer;
