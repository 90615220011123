import { t } from "i18next";
import { useState } from "react";
import { FormGroup, InputGroup, Label, InputGroupText, Input } from "reactstrap";


export default function PasswordField({label, children, ...rest}){

    const [passwordSee, setPasswordSee] = useState(false)

    return(
        <FormGroup className="mb-3">
        <Label>{t(label)}</Label>
        <InputGroup>
          <InputGroupText
            onClick={() => {
              setPasswordSee(!passwordSee);
            }}
          >
            {passwordSee && <i className="fas fa-eye"></i>}
            {!passwordSee && <i className="fas fa-eye-slash"></i>}
          </InputGroupText>
          <Input
            name="password"
            type={passwordSee ? "text" : "password"}
            className="form-control"
            {...rest}
          />
         {children}
        </InputGroup>
      </FormGroup>
    )
}