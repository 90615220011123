import { Card, CardBody } from "reactstrap";
import ResultHandler from "../ResultHandler";
import "./CustomTable.css";
import i18next, { t } from "i18next";

export default function CustomTable({ cols, data, emptyMsg, loading, error }) {

  const getColStyle = (col) => {
    return col.style.textAlign == 'right' ? {...col.style, textAlign: i18next.language == 'ar'?  "left": "right"} : col.style
  }
  
  return (
    <div>
      <Card>
        <CardBody className="">
          <div className="custom-table-row d-flex align-items-center">
            {cols.map((col, i) => (
              <span key={i} style={{ fontWeight: "bold", ...getColStyle(col)}}>
                {t(col.label)}
              </span>
            ))}
          </div>
          <ResultHandler loading={loading} error={error} />
          {data && data.length == 0 && (
            <p className="font-size-17 text-center mb-0 empty-table">{t(emptyMsg)}</p>
          )}
          {data &&
            data.map((row) => (
              <div
                key={row.id}
                className="custom-table-row d-flex align-items-center"
              >
                {cols.map((col, i) => (
                  <span key={i} style={getColStyle(col)}>
                    {(col.renderer ? col.renderer(row) : row[col.key]) || "-"}
                  </span>
                ))}
              </div>
            ))}
        </CardBody>
      </Card>
    </div>
  );
}
