import i18next from "i18next"
import { myAxios } from "./axios"

export function login(data) {
  const config = {
    headers: {
      'Accept-language': i18next.language
    }
  }
  return myAxios.post("/auth/login", data, config)
}



