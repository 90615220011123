import * as Yup from "yup";
import { formatValidationErr, handleMultilangFields } from "../../../hooks/utils";

export const FORM_FIELDS = [
  { key: "title", label: "Name", isMultilang: true },
  { key: "description", label: "Homepage description", type: "textarea", hasCounter: true, maxLength: 110, isMultilang: true },
  { key: "content", label: "Content", type: "html", isMultilang: true },

  { key: "image", label: "Featured Image", type: "file" },
];

export const INITIAL_VALS = {
  title: "",
  description: "",
  content: "",
  image: null,
};

export function handleFormatFormData(vals, lang) {
  const values = handleMultilangFields({values: vals, lang, fields: FORM_FIELDS})
  const formData = new FormData();

  Object.keys(values).map((key) => {
    if (values[key] || values[key] == false) {
      formData.append(key, values[key]);
    }
  });
  return formData;
}
export const VALIDATION_SCHEMA = Yup.object().shape({
  title: Yup.string().required(formatValidationErr("Title")),
  description: Yup.string().nullable().required(formatValidationErr("Description")),
  content: Yup.string().nullable(),

  image_preview: Yup.string()
    .nullable()
    .required(formatValidationErr("Featured Image")),
});
