import { myAxios } from "./axios"

export function getSlides(data, { page, perPage }) {
    let config = {
        params: {
            page,
            perPage,
            filter: data

        },
    }
    return myAxios.get("/homeSlides", config)
}

export function searchAndExport(data) {
    let config = {
        params: {
            filter: data
        },
    }
    return myAxios.get("/homeSlides/export", config)
}

export function getSlide(id) {
    return myAxios.get("/homeSlides/" + id)
}

export function addSlide(data) {
    return myAxios.post("/homeSlides/create", data)
}

export function editSlide(id, data) {
    return myAxios.put("/homeSlides/" + id + "/update", data)
}

export function deleteSlide(id) {
    return myAxios.delete("/homeSlides/" + id + "/delete")
}
