import React, { useState } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import CustomTable from "../../../components/CustomTable/CustomTable";
import moment from "moment";

import PaginationHandler from "../../../components/PaginationHandler";
import { deleteEvent, getEvents } from "../../../api/events";
import TableHeaderHandler from "../../../components/TableHeaderHandler";
import { usePagination } from "../../../hooks/usePagination";
import { PERMISSIONS } from "../../../api/permissions";
import TableAction from "../../../components/TableAction";
import { Button } from "reactstrap";
import EventGalleryModal from "./media/EventGalleryModal";

export default function EventsList() {
  const {
    response,
    loading,
    error,
    setPage,
    page,
    setPerPage,
    totalPages,
    setPayload,
  } = usePagination(getEvents);

  const [selectedEvent, setSelectedEvent] = useState(null)

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs
          isMainPage={true}
          title={"Events"}
          hasAddBtn={true}
          addUrl="/add-event"
        />

        <CustomTable
          emptyMsg={"No Events Found."}
          data={response?.data?.events}
          loading={loading}
          error={error}
          cols={[
            {
              label: "Icon",
              key: "icon",
              renderer: (row) => (
                <img className="custom-rounded" src={row.logo} />
              ),
              style: {
                width: "5%",
              },
            },
            { label: "Title", key: "title", style: { width: "20%" } },
            { label: "Location", key: "location", style: { width: "20%" } },
            {
              label: "Start Date",
              key: "date",
              renderer: (row) => moment(row.date).format("DD MMM YYYY"),
              style: { width: "20%" },
            },
            {
              label: "End Date",
              key: "endDate",
              renderer: (row) => moment(row.endDate).format("DD MMM YYYY"),
              style: { width: "20%" },
            },
            {
              label: "Action",
              key: "action",
              style: { width: "15%", textAlign: "right" },
              renderer: (row) => {
                return (
                  <>
                    <Button color="dark" onClick={() => setSelectedEvent(row)} style={{marginRight:"5px"}}>
                      <i className="bx bxs-image font-size-16 align-middle"></i>
                    </Button>
                    <TableAction
                      hasEdit={true}
                      hasDelete={true}
                      editPermission={PERMISSIONS.CATEGORY_EDIT}
                      deletePermission={PERMISSIONS.CATEGORY_DELETE}
                      editUrl={"/edit-event/" + row.id}
                      id={row.id}
                      deleteApi={deleteEvent}
                      onDeleteSuccess={() => setPayload({})}
                    />
                  </>
                );
              },
            },
          ]}
        />

        {response.data && !loading && (
          <PaginationHandler
            page={page}
            setPage={setPage}
            totalPages={totalPages}
          />
        )}

        {selectedEvent && <EventGalleryModal 
          isShow={selectedEvent ? true : false}
          handleClose={() => setSelectedEvent(null)}
          event={selectedEvent}
        />}
      </div>
    </div>
  );
}
