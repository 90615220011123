import { getCategories } from "../../../api/categories";
import * as Yup from "yup";
import { formatValidationErr, handleMultilangFields } from "../../../hooks/utils";

export const FORM_FIELDS = [
  { key: "title", label: "Name", isMultilang: true },
  { key: "content", label: "Content", type: "html", isMultilang: true },
  { key: "tags", label: "Tags", type: "textarea", isMultilang: true },

  {
    key: "categoryId",
    label: "Category",
    type: "select",
    api: getCategories,
    apiResKey: "categories",
  },
  { key: "image", label: "Featured Image", type: "file", notes: "Size: 850 x 600" },
  { key: "isFeatured", label: "Featured in Homepage", type: "checkbox" },
];

export const INITIAL_VALS = {
  title: "",
  categoryId: null,
  image: null,
  isFeatured: false,
};

export function handleFormatFormData(vals, lang) {
  vals.tags = vals.tags.split('\n').join(',')

  const values = handleMultilangFields({values: vals, lang, fields: FORM_FIELDS})
  const formData = new FormData();
  Object.keys(values).map((key) => {
    if (values[key] || values[key] == false) {
    
        formData.append(key, values[key]);

      
    }
  });
  return formData;
}

export const VALIDATION_SCHEMA = Yup.object().shape({
  title: Yup.string().required(formatValidationErr("Title")),
  categoryId: Yup.number().nullable().required(formatValidationErr("Category")),
  image_preview: Yup.string()
    .nullable()
    .required(formatValidationErr("Featured Image")),
});
