import React from "react"
import { Redirect } from "react-router-dom"

import Login from "../pages/Login"

import AdminsList from "../pages/dashboard/admins/AdminsList";
import AddAdmin from "../pages/dashboard/admins/AddAdmin";
import EditAdmin from "../pages/dashboard/admins/EditAdmin";
import EditProfile from "../pages/dashboard/admins/EditProfile";
import RolesList from "../pages/dashboard/roles/RolesList";
import AddRole from "../pages/dashboard/roles/AddRole";
import EditRole from "../pages/dashboard/roles/EditRole";
import CategoriesList from "../pages/dashboard/categories/CategoriesList";
import AddCategory from "../pages/dashboard/categories/AddCategory";
import EditCategory from "../pages/dashboard/categories/EditCategory";

import CareersList from "../pages/dashboard/careers/CareersList";
import EditCareer from "../pages/dashboard/careers/EditCareer";
import AddCareer from "../pages/dashboard/careers/AddCareer";
import DepartmentsList from "../pages/dashboard/departments/DepartmentsList";
import LocationsList from "../pages/dashboard/locations/LocationsList";

import PostsList from "../pages/dashboard/posts/PostsList";
import EditPost from "../pages/dashboard/posts/EditPost";
import AddPost from "../pages/dashboard/posts/AddPost";
import MembersList from "../pages/dashboard/members/MembersList";
import AddMember from "../pages/dashboard/members/AddMember";
import EditMember from "../pages/dashboard/members/EditMember";
import ServicesList from "../pages/dashboard/services/ServicesList";
import AddService from "../pages/dashboard/services/AddService";
import EditService from "../pages/dashboard/services/EditService";
import PagesList from "../pages/dashboard/pages/PagesList";
import AddPage from "../pages/dashboard/pages/AddPage";
import EditPage from "../pages/dashboard/pages/EditPage";
import SectionsList from "../pages/dashboard/page-sections/SectionsList";
import EditSection from "../pages/dashboard/page-sections/EditSection";
import AddSection from "../pages/dashboard/page-sections/AddSection";
import EventsList from "../pages/dashboard/events/EventsList";
import EditEvent from "../pages/dashboard/events/EditEvent";
import AddEvent from "../pages/dashboard/events/AddEvent";
import DevelopersList from "../pages/dashboard/developers/DevelopersList";
import ApplicantsList from "../pages/dashboard/careers-applicants/ApplicantsList";
import AddApplicant from "../pages/dashboard/careers-applicants/AddApplicant";
import EditApplicant from "../pages/dashboard/careers-applicants/EditApplicant";
import SlidesList from "../pages/dashboard/home-slides/SlidesList";
import AddSlide from "../pages/dashboard/home-slides/AddSlide";
import EditSlide from "../pages/dashboard/home-slides/EditSlide";
import ContactSubmissionsList from "../pages/dashboard/contact-submissions/ContactSubmissionsList";
import EventRegistrationsList from "../pages/dashboard/event-registrations/EventRegistrationsList";
import GetInTouchSubmissionsList from "../pages/dashboard/inTouch-submissions/GetInTouchSubmissionsList";
import NewsSubmissionsList from "../pages/dashboard/news-submissions/NewsSubmissionsList";
import InterestedinList from "../pages/dashboard/interestedin-options/InterestedinList";
import SeosList from "../pages/dashboard/seo/SeoList";
import EditSeo from "../pages/dashboard/seo/EditSeo";
import LogsList from "../pages/dashboard/logs/LogsList";
import FileManager from "../pages/dashboard/file-manager/FileManager";


const adminRoutes = [
  {
    path: "/admins",
    component: AdminsList,
  },
  // {
  //   path: "/my-admins",
  //   component: MyAdminsList,
  // },
  {
    path: "/add-admin",
    component: AddAdmin,
  },
  {
    path: "/edit-admin/:id",
    component: EditAdmin,
  },
  // {
  //   path: "/get-admin/:id",
  // },
  { path: "/edit-profile", component: EditProfile },
  // {
  //   path: "/configration",
  //   component: Configration,
  // },
  // {
  //   path: "/all-notifications",
  // },
]

const authProtectedRoutes = [


  { path: "/categories", component: CategoriesList },
  { path: "/edit-category/:id", component: EditCategory },
  { path: "/add-category", component: AddCategory },

  { path: "/careers", component: CareersList },
  { path: "/edit-career/:id", component: EditCareer },
  { path: "/add-career", component: AddCareer },

  { path: "/applicants/:type?", component: ApplicantsList },

  { path: "/edit-applicant/:id", component: EditApplicant },
  { path: "/add-applicant", component: AddApplicant },

  { path: "/contacts", component: ContactSubmissionsList },
  { path: "/get-in-touch", component: GetInTouchSubmissionsList },
  { path: "/newsletter", component: NewsSubmissionsList },


  { path: "/home-slides", component: SlidesList },
  { path: "/edit-slide/:id", component: EditSlide },
  { path: "/add-slide", component: AddSlide },

  { path: "/departments", component: DepartmentsList },
  { path: "/locations", component: LocationsList },
  { path: "/interestedin-options", component: InterestedinList },


  { path: "/services", component: ServicesList },
  { path: "/edit-service/:id", component: EditService },
  { path: "/add-service", component: AddService },

  { path: "/pages", component: PagesList },
  { path: "/edit-page/:id", component: EditPage },
  { path: "/add-page", component: AddPage },

  { path: "/pages/:pageId/sections", component: SectionsList },
  { path: "/edit-section/:id", component: EditSection },
  { path: "/pages/:pageId/add-section", component: AddSection },
  
  { path: "/posts", component: PostsList },
  { path: "/edit-post/:id", component: EditPost },
  { path: "/add-post", component: AddPost },

  { path: "/members", component: MembersList },
  { path: "/edit-member/:id", component: EditMember },
  { path: "/add-member", component: AddMember },

  
  { path: "/developers", component: DevelopersList },
  

  { path: "/events", component: EventsList },
  { path: "/edit-event/:id", component: EditEvent },
  { path: "/add-event", component: AddEvent },

  { path: "/events-registrations", component: EventRegistrationsList },

  { path: "/pages-seo", component: SeosList },
  { path: "/edit-seo/:id", component: EditSeo },

  
  { path: "/logs", component: LogsList },
  { path: "/file-manager", component: FileManager },


  ...adminRoutes,
  { path: "/roles", component: RolesList, },
  {
    path: "/add-role",
    component: AddRole,
    // permission: PERMISSIONS.ROLE_CREATE,
  },
  {
    path: "/edit-role/:id",
    component: EditRole,
    // permission: PERMISSIONS.ROLE_EDIT,
  },

]

const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/", component: Login },

]

export { authProtectedRoutes, publicRoutes }
