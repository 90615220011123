import { usePagination } from "../hooks/usePagination";
import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";

import Select from "react-select";
import "./style.css";

function debounce(func, timeout = 500) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export default function SearchSelectBox(props) {
  const { t } = useTranslation();
  const [searchTxt, setSearchTxt] = useState("");
  const { response, setPayload, setMetaKey, meta } = usePagination(
    props.api,
    props.apiMeta
  );

  // useEffect(() => {
  //   if(searchTxt != null){
  //     processChange()
  //   }
  // }, [searchTxt])

  // const processChange =useCallback(debounce(() => setMetaKey('txt', searchTxt)),[]);

  const setSearch = useCallback(
    debounce((e) => setMetaKey("txt", e)),
    []
  );
  const arr = response?.data?.[props.type] || response?.data || [];

  function getValue(){
    if(!props.isMulti){

      return  props.value
      ? {
          label: arr.find((op) => op.id === props.value)?.[props.label],
          value: props?.value,
        }
      : null
    }else{
      return props.value ? arr.filter(o => props.value.includes(o.id)).map(o => ({label: o[props.label], value: o.id})) : []
    }
  }

  return (
    <>
      <Select
        isDisabled={props.disabled}
        placeholder={t("forms.Select") + " ..."}
        value={
         getValue()
        }
        onChange={
          props.onChange ||
          ((data) => {
            props.setValue(data);
          })
        }
        isClearable={true}
        onFocus={props.handleFocus}
        onBlur={props.handleBlur}
        isMulti={props.isMulti}
        inputValue={searchTxt}
        onInputChange={(t) => {
          setSearchTxt(t);
          setSearch(t);
        }}
        options={arr
          .filter((c) => c.id != props.exclude)
          .map((c) => {
            return {
              label: c[props.label],
              value: c.id,
            };
          })}
        className={props.error ? "invalid api-select" : "api-select"}
      />

      {props.error ? (
        <div
          style={{
            width: "100%",
            marginTop: "0.25rem",
            fontSize: "100%",
            color: "#f46a6a",
          }}
        >
          {props.error}
        </div>
      ) : null}
    </>
  );
}
