import React, { useEffect, useState } from "react";

import { Row, Col, Form, Modal, ModalBody, Button, Label } from "reactstrap";
// Formik validation
import { useFormik } from "formik";
import ResultHandler from "../../../../components/ResultHandler";

import FormButtonHandler from "../../../../components/FormButtonHandler";
import { useResponse } from "../../../../hooks/useResponse";

import { addEventMedia, getEventGallery } from "../../../../api/events";
import ChooseImage from "../../../../components/ChooseImage";
import CreateMediaForm from "./CreateMediaForm";
import MediaCard from "./MediaCard";

const EventGalleryModal = ({ isShow, handleClose, event }) => {
  useEffect(() => {
    setGallery([]);
  }, [isShow]);
  /////FETCH GALLERY

  const [gallery, setGallery] = useState();
  const { setData, loading, error, success } = useResponse(
    getEventGallery,
    (res) => {
      setGallery(res.data.Media);
    }
  );

  useEffect(() => {
    if (event) {
      setData({ type: "add", data: event.id });
    }
  }, [event]);

  /// ADD ITEM

  const [formOpen, setFormOpen] = useState(false);

  return (
    <Modal size="lg" isOpen={isShow} toggle={handleClose} centered={true}>
      <ModalBody className="custom-rounded py-5 px-5">
        <Row>
          <Col lg={12}>
            <div className="mb-4 d-flex justify-content-between align-items-start">
              <h2 className=" text-center">{event.title} Gallery</h2>
              {!formOpen && (
                <Button
                  onClick={() => setFormOpen(true)}
                  size="lg"
                  color="primary"
                >
                  {gallery?.length > 0 ? "Add more files" : "Add gallery files"}
                </Button>
              )}
            </div>
          </Col>
        </Row>

        {formOpen && (
          <CreateMediaForm eventId={event.id} handleCancel={() => setFormOpen(false)} onSuccess={(url) => setGallery(g => ([...g, url]))} />
        )}
        <Row style={{ display: formOpen ? "none" : "flex" }}>
          <ResultHandler loading={loading} />
          {gallery?.map((media) => (
            <Col md={3} key={media.id}>
              <MediaCard media={media} onMarkFeaturedSuccess={() => setGallery(g => {
                return g.map(m => {
                  if(m.id == media.id){
                    return {...m, isFeatured: true}
                  }else{
                    return {...m, isFeatured: false}
                  }
                })
              })} onDeleteSuccess={() => setGallery(g => g.filter(im => im.id != media.id))} />
            </Col>
          ))}
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default EventGalleryModal;
