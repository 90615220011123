import React, { useEffect, lazy } from "react";

import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import Pages404 from "./components/PageNotFound";
// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes";

// Import all middleware
import Authmiddleware from "./routes/route";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

const App = ({}) => {
  
  useEffect(() => {
    if(localStorage.getItem('lang') == "ar" ){
      require(`./assets/scss/theme-rtl.scss`);
    }else{
      require(`./assets/scss/theme.scss`);
    }

    
  }, [])
  

  return (
    <React.Fragment lang={'en'}>
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={VerticalLayout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}

          <Route path="*" exact={true} component={Pages404} />
        </Switch>
      </Router>
    </React.Fragment>
  );
};

export default App;
