import * as Yup from "yup";
import { formatValidationErr, handleMultilangFields } from "../../../hooks/utils";

export const FORM_FIELDS = [
  { key: "title", label: "SEO Title", isMultilang: true },
  { key: "description", label: "Meta Description", type: "textarea", isMultilang: true },

  { key: "social_title", label: "Social media title", type: "textarea", isMultilang: true },
  { key: "social_description", label: "Social media Description", type: "textarea", isMultilang: true },

  { key: "social_image", label: "Social media image", type: "file", notes: "( Facebook, Instagram ) Size: 1200 x 630" },


];

export const INITIAL_VALS = {
  title: "",
  description: "",
  social_image: null,
};

export function handleFormatFormData(vals, lang) {
  const values = handleMultilangFields({values: vals, lang, fields: FORM_FIELDS})
  const formData = new FormData();

  Object.keys(values).map((key) => {
    if (values[key] || values[key] == false) {
      formData.append(key, values[key]);
    }
  });

  return formData;
}
export const VALIDATION_SCHEMA = Yup.object().shape({

});
