import { getCategories } from "../../../api/categories";
import { getPages } from "../../../api/pages";


export const FORM_FIELDS = [
    {key: "title", label: "Title" },
  ]

export const INITIAL_VALS = {
  title: "",

}

export function handleFormatFormData(values){
  
    return values
}