import { myAxios } from "./axios"

export function getSections(data) {
    let config = {
        params: {
        
            ...(data || {})            

        },
    }
    return myAxios.get("/pages/section-page", config)
}


export function getSection(id) {
    return myAxios.get("/sections/" + id)
}

export function addSection(data) {
    return myAxios.post("/sections/create", data)
}

export function editSection(id, data) {
    return myAxios.put("/sections/" + id + "/update", data)
}

export function deleteSection(id) {
    return myAxios.delete("/sections/" + id + "/delete")
}
