import { myAxios } from "./axios"

export function getCategories(data) {
    let config = {
        params: {
           
            title: data ? data.txt : null,
        },
    }
    return myAxios.get("/categories", config)
}


export function getCategory(id) {
    return myAxios.get("/categories/" + id)
}

export function addCategory(data) {
    return myAxios.post("/categories/create", data)
}

export function editCategory(id, data) {
    return myAxios.put("/categories/" + id + "/update", data)
}

export function deleteCategory(id) {
    return myAxios.delete("/categories/" + id + "/delete")
}

export function publishCategory(id, data) {
    if (data.type === "publish") {
        return myAxios.post("/categories/" + id + "/publish")
    } else {
        return myAxios.post("/categories/" + id + "/unpublish")
    }
}

export function importCategories(data) {
    return myAxios.post("/categories/import", data)
}