import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import DeleteModal from "../../../components/Common/DeleteModal";
import { deleteRole, getRoles } from "../../../api/roles";
import { usePagination } from "../../../hooks/usePagination";
import ResultHandler from "../../../components/ResultHandler";
import PaginationHandler from "../../../components/PaginationHandler";
import TableHeaderHandler from "../../../components/TableHeaderHandler";
import { PERMISSIONS } from "../../../api/permissions";
import { useTranslation } from "react-i18next";
import { useResponse } from "../../../hooks/useResponse";
import TableAction from "../../../components/TableAction";
import EmptyData from "../../../components/EmptyData";
import { Table, Thead, Tbody, Td, Tr, Th } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import CustomTable from "../../../components/CustomTable/CustomTable";

export default function RolesTable() {
  const { t } = useTranslation();

  const navigate = useHistory();

  const {
    response,
    loading,
    error,
    setPage,
    page,
    setPerPage,
    totalPages,
    setPayload,
  } = usePagination(getRoles);

  return (
    <>
      <TableHeaderHandler
        permission={PERMISSIONS.ROLE_CREATE}
        setPage={setPage}
        setPerPage={setPerPage}
        addUrl="/add-role"
      />

      <CustomTable
        emptyMsg={"No Roles Found."}
        data={response?.data?.roles}
        loading={loading}
        error={error}
        cols={[
          { label: "Name", key: "name", style: { width: "20%" } },
          {
            label: "Action",
            key: "action",
            style: { width: "80%", textAlign: "right" },
            renderer: (row) => {
              return (
                <>
                  <TableAction
                    hasEdit={true}
                    hasDelete={true}
                    editPermission={PERMISSIONS.CATEGORY_EDIT}
                    deletePermission={PERMISSIONS.CATEGORY_DELETE}
                    editUrl={"/edit-role/" + row.id}
                    id={row.id}
                    deleteApi={deleteRole}
                    onDeleteSuccess={() => setPayload({})}
                  />
                </>
              );
            },
          },
        ]}
      />

      {response.data && !loading && (
        <PaginationHandler
          page={page}
          setPage={setPage}
          totalPages={totalPages}
        />
      )}
    </>
  );
}
