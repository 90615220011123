import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap";

import React from "react";

import { useTranslation } from "react-i18next";
import { FORM_FIELDS } from "./CONSTANT";
import { FieldInput } from "../../../components/FieldInput";

const SECTIONS_FIELDS = [
  { key: "generic_candidates", fields: ["content"] },

  { key: "our-story", fields: ["title", 'content', "media"] },
  { key: "video", fields: ["content", "background", "media"] },
];

export default function SectionForm({ validation, sectionToken }) {
  const { t } = useTranslation();


  return (
    <Row>
      {FORM_FIELDS.filter((f) =>
        SECTIONS_FIELDS.find(
          (sec) => sec.key == sectionToken
        ).fields.includes(f.key)
      ).map((f) => (
        <FieldInput
          key={f.key}
          f={{
            ...f,
            ...(f.key == "media" ? { fileType: validation.values.type } : {}),
          }}
          validation={validation}
        />
      ))}
    </Row>
  );
}
