import React, { useState } from "react";
//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { PERMISSIONS } from "../../../api/permissions";
import TableAction from "../../../components/TableAction";
import { usePagination } from "../../../hooks/usePagination";
import CustomTable from "../../../components/CustomTable/CustomTable";
import { deleteApplicant, getApplicants } from "../../../api/applicants";
import PaginationHandler from "../../../components/PaginationHandler";
import { Button, Input, Label } from "reactstrap";
import { myAxios } from "../../../api/axios";
import {
  deleteContactSubmission,
  exportContactSubmissions,
  getContactSubmissions,
} from "../../../api/contacts";
import { ExportBtn } from "../../../components/ExportBtn";
import DateFilter from "../../../components/DateFilter";
import { t } from "i18next";
import moment from "moment";

export default function ContactSubmissionsList() {
  const {
    response,
    loading,
    error,
    setPage,
    page,
    setMeta,
    totalPages,
    setPayload,
  } = usePagination(getContactSubmissions);

  const [filters, setFilters] = useState({
    from: null,
    to: null,
    name: "",
    phone: ""
  });

  function handleFilter() {
    setMeta((m) => ({
      page: 1,
      initialLoad: true,
      payload: filters,
    }));
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="d-flex justify-content-between">
          <Breadcrumbs title={"Contact Submissions"} isMainPage={true} />

          <ExportBtn apiMeta={filters} api={exportContactSubmissions} />
        </div>

        <div
          className="d-flex align-items-center filters-wrapper mb-4"
          style={{ gap: 20 }}
        >
          <DateFilter
            value={filters}
            onChange={(k, v) => setFilters((f) => ({ ...f, [k]: v }))}
          />

          <div className="d-flex align-items-center " style={{ gap: 10 }}>
            <Label>{t("Name")}</Label>
            <Input
              type="text"
              value={filters.name}
              onChange={(e) =>
                setFilters((f) => ({ ...f, name: e.target.value }))
              }
            />
          </div>

          <div className="d-flex align-items-center " style={{ gap: 10 }}>
            <Label>{t("Phone")}</Label>
            <Input
              type="text"
              value={filters.phone}
              onChange={(e) =>
                setFilters((f) => ({ ...f, phone: e.target.value }))
              }
            />
          </div>

          <Button onClick={handleFilter} color="primary">
            {t("Search")}
          </Button>
        </div>

        <CustomTable
          emptyMsg={"No Contact Submissions Found."}
          data={response?.data?.contact}
          loading={loading}
          error={error}
          cols={[
            { label: "Name", key: "name", style: { width: "20%" } },
            { label: "Phone", key: "phone", style: { width: "20%" } },
            { label: "Message", key: "message", style: { width: "20%" } },
            {
              label: "Date",
              key: "date",
              renderer: (row) => moment(row.createdAt).format("DD MMM YYYY"),
              style: { width: "20%" },
            },

            {
              label: "Action",
              key: "action",
              style: { width: "20%", textAlign: "right" },
              renderer: (row) => {
                return (
                  <>
                    <TableAction
                      hasDelete={true}
                      editPermission={PERMISSIONS.CATEGORY_EDIT}
                      deletePermission={PERMISSIONS.CATEGORY_DELETE}
                      id={row.id}
                      deleteApi={deleteContactSubmission}
                      onDeleteSuccess={() => setPayload({})}
                    />
                  </>
                );
              },
            },
          ]}
        />

        {response.data && !loading && (
          <PaginationHandler
            page={page}
            setPage={setPage}
            totalPages={totalPages}
          />
        )}
      </div>
    </div>
  );
}
