import { Button } from "reactstrap";
import {
  deleteEventMedia,
  markEventMediaFeatured,
} from "../../../../api/events";
import { useResponse } from "../../../../hooks/useResponse";
import "./MediaCard.css";
import { useEffect, useState } from "react";
export default function MediaCard({
  media,
  onDeleteSuccess,
  onMarkFeaturedSuccess,
}) {
  const { loading, error, setData } = useResponse(deleteEventMedia, () => {
    onDeleteSuccess();
  });

  const {
    loading: featuredLoading,
    error: featuredErr,
    setData: setFeatured,
  } = useResponse(markEventMediaFeatured, () => {
    onMarkFeaturedSuccess();
  });

  const cardLoading = loading || featuredLoading;

  return (
    <div
      className="custom-rounded"
      style={{ position: "relative", border: "1px solid #bcbcbc" }}
    >
      {media.type == "img" && (
        <img
          src={media.url}
          className="custom-rounded"
          style={{ width: "100%", height: "150px", objectFit: "cover" }}
        />
      )}
      {media.type == "video" && (
        <video
          className="custom-rounded"
          style={{ width: "100%", height: "150px", objectFit: "cover" }}
        >
          <source src={media.url} />
        </video>
      )}
      {media.type == "embedded" && (
        <div
          className="custom-rounded iframe"
          style={{ width: "100%", height: "150px", objectFit: "cover" }}
          dangerouslySetInnerHTML={{ __html: media.url }}
        />
      )}

      {!cardLoading && (
        <i
          onClick={() => setData({ type: "add", data: media.id })}
          className="mdi remove-img mdi-close font-size-16 align-middle"
        ></i>
      )}
      {media.isFeatured && (
        <i
          style={{
            color: "gold",
            position: "absolute",
            top: "15px",
            right: "15px",
          }}
          className="bx bxs-star font-size-22 align-middle"
        ></i>
      )}
      <div
        className="d-flex"
        style={{
          gap: "10px",
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          bottom: "20px",
        }}
      >
        {!cardLoading && !media.isFeatured && (
          <Button
            color="primary"
            onClick={() =>
              setFeatured({
                type: "add",
                data: { mediaId: media.id, eventId: media.eventId },
              })
            }
          >
            <i className="bx bx-star font-size-16 align-middle"></i>
          </Button>
        )}
        {cardLoading && (
          <Button
            color="dark"
            style={{ position: "absolute", right: "10px", bottom: "10px" }}
          >
            Wait
          </Button>
        )}
      </div>
    </div>
  );
}
