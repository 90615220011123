import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap";
// Formik validation
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useHistory, useParams } from "react-router-dom";
import ResultHandler from "../../../components/ResultHandler";
import { editEvent, getEvent } from "../../../api/events";
import FormButtonHandler from "../../../components/FormButtonHandler";
import useCustomValidation from "../validationSchema";
import { useResponse } from "../../../hooks/useResponse";
import { useTranslation } from "react-i18next";
import { useGetInstence } from "../../../hooks/useGetInstence";
import EventForm from "./EventForm";
import { FORM_FIELDS, handleFormatFormData, INITIAL_VALS, VALIDATION_SCHEMA } from "./CONSTANT";
import { sanitizeMultilangObj } from "../../../hooks/utils";
import { useRecoilState } from "recoil";
import langState from "../../../atom/lang";
import FormLangSwitcher from "../../../components/FormLangSwitcher";

const EditEvent = () => {
  let navigate = useHistory();

  const [lang] = useRecoilState(langState)
  
  const { t } = useTranslation();

  const params = useParams();
  const { instenceLoading, instence: editedEvent } = useGetInstence({
    apiMeth: () => getEvent(params.id),
    multilangFields: FORM_FIELDS.filter(f => f.isMultilang).map(f => f.key),
    getInstence: ({Event}) => {
      const {title, location, date, endDate, developers, interestedInOptions, logo, banner, content, mob_video, desktop_video} = Event
      return {
        title,
        location,
        date,
        endDate,
        content,
        logo_preview: logo,
        banner_preview: banner,
        developers: developers.map(d => d.id),
        interestedIns: interestedInOptions.map(o => o.id), 

        mob_video: null,
        mob_video_preview: mob_video, 
        desktop_video: null, 
        desktop_video_preview: desktop_video
      }
      
    }
  });

  const { setData, loading, error, success } = useResponse(editEvent, () => {
    navigate.push("/events");
  });


  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      ...INITIAL_VALS,
      ...(editedEvent || {}),
    },
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: (values) => {
      const formData = handleFormatFormData(values, lang);
      setData({ type: "edit", id: params.id, data: formData });
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <div className="d-flex justify-content-between">
            <Breadcrumbs title={"Edit Event"} />
            <FormLangSwitcher />
          </div>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  {editedEvent && (
                    <Form
                      className="needs-validation"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <EventForm validation={validation} />
                      <ResultHandler
                        loading={instenceLoading}
                        error={error}
                        success={success}
                        successMsg={"Updated Successfully!"}
                      />
                      <FormButtonHandler btnTxt="Save" loading={loading} />
                    </Form>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditEvent;
