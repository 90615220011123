import { myAxios } from "./axios";

export function getContactSubmissions(data, { page, perPage }) {
  let config = {
    params: {
      page,
      perPage,
      ...(data || {}),
    },
  };
  return myAxios.get("/contacts", config);
}

export function exportContactSubmissions(data) {
  let config = {
    params: {
      ...(data || {}),
    },
  };
  return myAxios.get("/contacts/export", config);
}

export function deleteContactSubmission(id) {
  return myAxios.delete("/contacts/" + id + "/delete");
}
