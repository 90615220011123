import Breadcrumbs from "../../../components/Common/Breadcrumb";
import React, { useState } from "react";
import { usePagination } from "../../../hooks/usePagination";
import ResultHandler from "../../../components/ResultHandler";
import PaginationHandler from "../../../components/PaginationHandler";
import { deleteDeveloper, getDevelopers } from "../../../api/developers";
import TableAction from "../../../components/TableAction";
import CustomTable from "../../../components/CustomTable/CustomTable";

import { PERMISSIONS } from "../../../api/permissions";
import DeveloperFormModal from "./DeveloperFormModal";
import { Card, CardBody, Col, Row } from "reactstrap";

export default function DevelopersList() {
  const {
    response,
    loading,
    error,
    setPage,
    page,
    setPerPage,
    totalPages,
    setPayload,
  } = usePagination(getDevelopers);

  const [formModalShow, setFormModalShow] = useState(false);
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs
          title={"Developers"}
          hasAddBtn={true}
          addTxt="Add New Developer"
          onAddBtnClick={() => setFormModalShow(true)}
          isMainPage={true}
        />

        <Row>
          {response?.data?.data?.map((dev, i) => {
            return (
              <Col key={i} md={2}>
                <Card>
                  <CardBody style={{ padding: "10px", position: "relative" }}>
                    <img style={{ width: "100%" }} src={dev.image} />
                    <div
                      style={{
                        position: "absolute",
                        left: "50%",
                        transform: "translateX(-50%)",
                        bottom: "10px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <TableAction
                        hasEdit={true}
                        hasDelete={true}
                        editPermission={PERMISSIONS.CATEGORY_EDIT}
                        deletePermission={PERMISSIONS.CATEGORY_DELETE}
                        id={dev.id}
                        deleteApi={deleteDeveloper}
                        onDeleteSuccess={() => setPayload({})}
                        onEditClick={() => {
                          setFormModalShow(dev);
                        }}
                        outline={true}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            );
          })}
        </Row>

        {response.data && !loading && (
          <PaginationHandler
            page={page}
            setPage={setPage}
            totalPages={totalPages}
          />
        )}

        <DeveloperFormModal
          key={formModalShow}
          isShow={formModalShow ? true : false}
          handleClose={() => setFormModalShow(false)}
          handleRefresh={() => setPayload({})}
          developer={typeof formModalShow == "object" ? formModalShow : null}
        />
      </div>
    </div>
  );
}
