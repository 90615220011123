import { getDepartments } from "../../../api/departments";


export const FORM_FIELDS = [
    {key: "image", label: "Logo", type: "file", notes: "Size: 165 x 165" },
  

]

export const INITIAL_VALS = {
  image: null
}

export function handleFormatFormData(values){
  
  const formData = new FormData()
  formData.append('name', 'DUMMY')
  if(values.image){
    
    formData.append('image', values.image)
  }

    return formData
}