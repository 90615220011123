//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import React, { useState } from "react";
import { usePagination } from "../../../hooks/usePagination";
import ResultHandler from "../../../components/ResultHandler";
import PaginationHandler from "../../../components/PaginationHandler";
import { deleteCategory, getCategories } from "../../../api/categories";
import TableAction from "../../../components/TableAction";
import { Button } from "reactstrap";
import { PERMISSIONS } from "../../../api/permissions";
import CustomTable from "../../../components/CustomTable/CustomTable";

export default function CategoriesList() {
  const {
    response,
    loading,
    error,
    setPage,
    page,
    setPerPage,
    totalPages,
    setPayload,
  } = usePagination(getCategories);
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs
          title={"Categories"}
          hasAddBtn={true}
          addUrl="/add-category"
          isMainPage={true}
        />
        <CustomTable
          emptyMsg={"No Categories Found."}
          data={response?.data}
          loading={loading}
          error={error}
          cols={[
            {
              label: "Icon",
              key: "icon",
              renderer: (row) => (
                <img className="custom-rounded" src={row.icon} />
              ),
              style: {
                width: "5%",
              },
            },
            { label: "Name", key: "name", style: { width: "25%" } },
            {
              label: "Description",
              key: "description",
              style: { width: "50%" },
            },

            {
              label: "Action",
              key: "action",
              style: { width: "20%", textAlign: "right" },
              renderer: (row) => {
                return (
                  <TableAction
                    hasEdit={true}
                    hasDelete={true}
                    editPermission={PERMISSIONS.CATEGORY_EDIT}
                    deletePermission={PERMISSIONS.CATEGORY_DELETE}
                    editUrl={"/edit-category/" + row.id}
                    id={row.id}
                    deleteApi={deleteCategory}
                    onDeleteSuccess={() => setPayload({})}
                  />
                );
              },
            },
          ]}
        />

        {response.data && !loading && (
          <PaginationHandler
            page={page}
            setPage={setPage}
            totalPages={totalPages}
          />
        )}
      </div>
    </div>
  );
}
