import Breadcrumbs from "../../../components/Common/Breadcrumb";
import React, { useEffect, useState } from "react";
import { usePagination } from "../../../hooks/usePagination";

import PaginationHandler from "../../../components/PaginationHandler";
import { deleteSection, getSections } from "../../../api/sections";
import TableAction from "../../../components/TableAction";

import { PERMISSIONS } from "../../../api/permissions";
import CustomTable from "../../../components/CustomTable/CustomTable";
import { useParams } from "react-router-dom";

export default function SectionsList() {
  const { pageId } = useParams();

  const {
    response,
    loading,
    error,
    setPage,
    page,
    setMeta,
    totalPages,
    setPayload,
  } = usePagination(getSections, null, false);

  useEffect(() => {
    setMeta(m => ({...m, initialLoad: true, payload: { pageToken: pageId }}))
  }, [pageId])

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs
          title={"Sections"}
          //hasAddBtn={true}
          isMainPage={true}
          //addUrl={"/pages/" + pageId + "/add-section"}
        />
        <CustomTable
          emptyMsg={"No Sections Found."}
          data={response?.data?.data?.Section}
          loading={loading}
          error={error}
          cols={[
            { label: "Title", key: "title", style: { width: "25%" } },

            {
              label: "Action",
              key: "action",
              style: { width: "75%", textAlign: "right" },
              renderer: (row) => {
                return (
                  <>
                    <TableAction
                      hasEdit={true}
                      editPermission={PERMISSIONS.CATEGORY_EDIT}
                      deletePermission={PERMISSIONS.CATEGORY_DELETE}
                      editUrl={"/edit-section/" + row.id}
                    />
                  </>
                );
              },
            },
          ]}
        />

        {response.data && !loading && (
          <PaginationHandler
            page={page}
            setPage={setPage}
            totalPages={totalPages}
          />
        )}
      </div>
    </div>
  );
}
