import { myAxios } from "./axios"

export function getApplicants(data, { page, perPage }) {
    let config = {
        params: {
            page,
            perPage,
            ...(data || {})

        },
    }
    return myAxios.get("/applicants", config)
}

export function exportApplicants(data) {
    let config = {
        params: {
      
            ...(data || {})

        },
    }
    return myAxios.get("/applicants/export", config)
}

export function getApplicant(id) {
    return myAxios.get("/applicants/" + id)
}

export function addApplicant(data) {
    return myAxios.post("/applicants/create", data)
}

export function editApplicant(id, data) {
    return myAxios.put("/applicants/" + id + "/update", data)
}

export function deleteApplicant(id) {
    return myAxios.delete("/applicants/" + id + "/delete")
}
