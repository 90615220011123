import { getDepartments } from "../../../api/departments";
import * as Yup from "yup";
import { formatValidationErr, handleMultilangFields } from "../../../hooks/utils";
import { getLocations } from "../../../api/locations";

export const FORM_FIELDS = [
  { key: "title", label: "Title", isMultilang: true },
  

  { key: "linkUrl", label: "Link Url" },
  { key: "linkText", label: "Link text", isMultilang: true },
];

export const INITIAL_VALS = {
  title: "",
  mediaType: "img",
  media: null,
  linkUrl: "",
  linkText: "",
};

export function handleFormatFormData(vals, lang) {
  const values = handleMultilangFields({values: vals, lang, fields: FORM_FIELDS})
  const formData = new FormData();

  Object.keys(values).map((key) => {
    if (values[key]) {
      formData.append(key, values[key]);
    }
  });

  return formData;
}

export const VALIDATION_SCHEMA = Yup.object().shape({
  title: Yup.string().required(formatValidationErr("Name")),
  mediaType: Yup.string().required(formatValidationErr("Media Type")),
  media_preview: Yup.string().nullable().required(formatValidationErr("Media")),
  linkUrl: Yup.string().required(formatValidationErr("Link Url")),
  linkText: Yup.string().required(formatValidationErr("Link Text")),
});
