import { myAxios } from "./axios"

export function getDepartments() {
  
    return myAxios.get("/departments")
}

export function getDepartment(id) {
    return myAxios.get("/departments/" + id)
}

export function addDepartment(data) {
    return myAxios.post("/departments/create", data)
}

export function editDepartment(data) {
    return myAxios.put("/departments/" + data.id + "/update", data)
}

export function deleteDepartment(id) {
    return myAxios.delete("/departments/" + id + "/delete")
}
