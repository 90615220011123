import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap";
// Formik validation
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useHistory, useParams } from "react-router-dom";
import ResultHandler from "../../../components/ResultHandler";
import { editApplicant, getApplicant } from "../../../api/applicants";
import FormButtonHandler from "../../../components/FormButtonHandler";
import useCustomValidation from "../validationSchema";
import { useResponse } from "../../../hooks/useResponse";
import { useTranslation } from "react-i18next";
import { useGetInstence } from "../../../hooks/useGetInstence";
import ApplicantForm from "./ApplicantForm";
import {
  handleFormatFormData,
  INITIAL_VALS,
  VALIDATION_SCHEMA,
} from "./CONSTANT";

const EditApplicant = () => {
  let navigate = useHistory();

  const params = useParams();
  const [editedApplicant, setEditedApplicant] = useState(null);

  const { instenceLoading, instenceError, instenceSuccess } = useGetInstence(
    getApplicant,
    params.id,
    (response) => {
      setEditedApplicant({
        ...response.data.data.Applicant,
        file: null, 
        file_preview: response.data.data.Applicant.file
      });
    }
  );

  const { setData, loading, error, success } = useResponse(editApplicant, () => {
    navigate.push("/applicants");
  });

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      ...INITIAL_VALS,
      ...editedApplicant,
    },
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: (values) => {
      const formData = handleFormatFormData(values);
      setData({ type: "edit", id: params.id, data: formData });
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={"Edit Applicant"} />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  {editedApplicant && (
                    <Form
                      className="needs-validation"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <ApplicantForm validation={validation} />
                      <ResultHandler
                        loading={instenceLoading}
                        error={error}
                        success={success}
                        successMsg={"Updated Successfully!"}
                      />
                      <FormButtonHandler btnTxt="Save" loading={loading} />
                    </Form>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditApplicant;
