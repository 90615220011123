import { getLocations } from "../../../api/locations";
import { handleMultilangFields } from "../../../hooks/utils";


export const FORM_FIELDS = [
    {key: "name", label: "Name", isMultilang: true },
    {key: "url", label: "Google maps url" },

]

export const INITIAL_VALS = {
  name: "",

}

export function handleFormatFormData(values, lang){

  

  return handleMultilangFields({values, fields: FORM_FIELDS, lang})
}