


export function formatValidationErr(label){


    return label + ' is required'
}



export function exportCsv(data){


    const blob = new Blob([data], { type: 'text/csv' });
    
    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    
    // Set the URL and download attribute of the anchor tag
    a.href = url;
    a.download = 'download.csv';
    
    // Trigger the download by clicking the anchor tag
    a.click()
}


export function handleMultilangFields({fields, values, lang}){

    const new_vals = {}

    Object.keys(values).map(fieldKey => {
        const field = fields.find(f => f.key == fieldKey)
        const isMultiLang = field?.isMultilang
        if(isMultiLang){
            new_vals[fieldKey+"_"+lang] = values[fieldKey]
        }else{
            new_vals[fieldKey] = values[fieldKey]
        }
        
    })

    return new_vals
}


export function sanitizeMultilangObj(obj, multilang_keys, lang){

    const new_obj = {}

    Object.keys(obj).map(key => {
        if(multilang_keys.includes(key)){
            new_obj[key] = obj[key]?.[lang] || ""

        }else{
            new_obj[key] = obj[key]
        }
    })

    return new_obj
}