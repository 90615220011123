import React, { useEffect } from "react";

import { Row, Col, Form, Modal, ModalBody, Button } from "reactstrap";
// Formik validation
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import ResultHandler from "../../../components/ResultHandler";
import { addDepartment, editDepartment, getDepartment } from "../../../api/departments";

import FormButtonHandler from "../../../components/FormButtonHandler";
import { useResponse } from "../../../hooks/useResponse";

import { FORM_FIELDS, handleFormatFormData, INITIAL_VALS } from "./CONSTANT";
import { FieldInput } from "../../../components/FieldInput";
import { useGetInstence } from "../../../hooks/useGetInstence";
import { getLocation } from "../../../api/locations";
import { useRecoilState } from "recoil";
import langState from "../../../atom/lang";
import FormLangSwitcher from "../../../components/FormLangSwitcher";
import { t } from "i18next";

const DepartmentFormModal = ({
  isShow,
  departmentId,
  handleClose,
  handleRefresh,
}) => {
  const [lang] = useRecoilState(langState);

  const { instenceLoading, instence: editedDepartment } = useGetInstence({
    apiMeth: () => getDepartment(departmentId),
    multilangFields: FORM_FIELDS.filter((f) => f.isMultilang).map((f) => f.key),
    getInstence: ({ Dept }) => {
      const { name, id } = Dept;
      return {
        id,
        name,
      };
    },
  });

  ///
  const { setData, loading, error, success } = useResponse(
    departmentId ? editDepartment : addDepartment,
    () => {
      handleRefresh();
      handleClose();
    }
  );

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      ...INITIAL_VALS,
      ...(editedDepartment || {}),
    },
    onSubmit: (values) => {
      const formData = handleFormatFormData(values, lang);
      setData({ data: formData, type: "add" });
    },
  });

  return (
    <Modal size="md" isOpen={isShow} toggle={handleClose} centered={true}>
      <ModalBody className="custom-rounded py-5 px-5">
        <Row>
          <Col lg={12}>
            <div className="d-flex justify-content-between">
          
              <h2 className="mb-4">{t((departmentId ? "Save" : "Add")+ " Department")}</h2>

              <FormLangSwitcher />
            </div>
          </Col>

          <Col xl="12">
            <Form
              className="needs-validation"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              {FORM_FIELDS.map((f) => (
                <FieldInput
                  style={{ marginBottom: "30px" }}
                  key={f.key}
                  f={f}
                  validation={validation}
                />
              ))}
              <ResultHandler
                error={error}
                success={success}
                successMsg={"Saved Successfully!"}
              />
              <div className="d-flex justify-content-between">
                <Button size="lg" color="dark">
                {t("Cancel")}
                </Button>
                <FormButtonHandler
                  btnTxt={departmentId ? "Save" : "Add"}
                  loading={loading}
                />
              </div>
            </Form>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default DepartmentFormModal;
