import * as Yup from "yup";
import { formatValidationErr, handleMultilangFields } from "../../../hooks/utils";

export const FORM_FIELDS = [
  { key: "title", label: "Name", isMultilang: true },
  { key: "jop", label: "Job title", isMultilang: true },
  { key: "isFeatured", label: "Type", type: "fixed-select", options: ['board', 'management'].map(v => ({label: v, value: v})) },

  { key: "content", label: "Bio", isMultilang: true, type: "html", condition: (formValues) => formValues?.isFeatured == 'board' },

  { key: "image", label: "Featured Image", type: "file", notes: "Size: 305 x 320" },
];

export const INITIAL_VALS = {
  title: "",
  jop: "",
  content: "",
  image: null,
  isFeatured: 'board',
};

export function handleFormatFormData(vals, lang) {
  const values = handleMultilangFields({values: vals, fields: FORM_FIELDS, lang})
  const formData = new FormData();

  Object.keys(values).map((key) => {
    if (values[key]) {
      if(key == 'isFeatured'){
        formData.append(key, values[key] == 'board');

      }else{
        formData.append(key, values[key]);

      }
    }
  });
  return formData;
}

export const VALIDATION_SCHEMA = Yup.object().shape({
  title: Yup.string().required(formatValidationErr("Title")),
  isFeatured: Yup.string().required(formatValidationErr("Type")),

  jop: Yup.string().required(formatValidationErr("Job")),
  image_preview: Yup.string().required(formatValidationErr("Image")),
});
