import React, { useEffect } from "react";

import {
  Row,
  Col,
  Form,
  Modal,
  ModalBody,
  Button,
} from "reactstrap";
// Formik validation
import { useFormik } from "formik";
;
import ResultHandler from "../../../components/ResultHandler";

import FormButtonHandler from "../../../components/FormButtonHandler";
import { useResponse } from "../../../hooks/useResponse";

import { FORM_FIELDS, handleFormatFormData, INITIAL_VALS } from "./CONSTANT";
import { FieldInput } from "../../../components/FieldInput";
import { editDeveloper, addDeveloper } from "../../../api/developers";
import { t } from "i18next";

const DeveloperFormModal = ({ isShow, developer, handleClose, handleRefresh }) => {
  const { setData, loading, error, success } = useResponse(
    (developer ? editDeveloper : addDeveloper),
    () => {
      handleRefresh();
      handleClose();
    }
  );

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      ...INITIAL_VALS,
      ...(developer ? {image_preview: developer.image} : {}),
    },
    onSubmit: (values) => {
      const formData = handleFormatFormData(values);

      setData({ data: {data: formData, ...(developer ? {id: developer.id} : {})}, type: "add" });
    },
  });

  return (
    <Modal size="md" isOpen={isShow} toggle={handleClose} centered={true}>
      <ModalBody className="custom-rounded py-5 px-5">
        <Row>
          <Col lg={12}>
            <h2 className="mb-4">{t((developer ? "Edit" : "Add")+" Developer")}</h2>
          </Col>
      
          <Col xl="12">
            <Form
              className="needs-validation"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              {FORM_FIELDS.map((f) => (
                <FieldInput style={{marginBottom: "30px"}} key={f.key} f={f} validation={validation} />
              ))}
              <ResultHandler
                error={error}
                success={success}
                successMsg={"Saved Successfully!"}
              />
              <div className="d-flex justify-content-between">
                <Button size="lg" color="dark">
                  {t("Cancel")}
                </Button>
              <FormButtonHandler
                btnTxt={developer ? "Save" : "Add"}
                loading={loading}
              />
              </div>
            </Form>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default DeveloperFormModal;
