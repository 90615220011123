// import FileManagerModal from "../pages/dashboard/file-manager/FileManagerModal"
import React, { useState } from "react";
import { Col, FormFeedback, Input, Label, Row } from "reactstrap";
import { useTranslation } from "react-i18next";

import "./ChooseImage.css";

export default function ChooseImage({fileType='img', ...props}) {
  const [open, setOpen] = useState(false);

  function removeImage() {
    if (!props.disabled) {
      props.handleChooseImage(null);
    }
  }

  const { t } = useTranslation();

  return (
    <Row>
      {!props.hideLabel && (
        <Col md={3}>
          <Label
            style={{
              marginBottom: "10px",
            }}
          >
            {props.label ? t(props.label) : t("forms.Image")}
            {props.notes && <span className="font-size-12 d-block">{props.notes}</span>}
          </Label>
          {props.error ? (
            <FormFeedback style={{ display: "block" }} type="invalid">
              {props.error}
            </FormFeedback>
          ) : null}
        </Col>
      )}

      <Col md={props.hideLabel ? 12 : 9}>
        <div
          className={props.error ? "ChooseImage invalid" : "ChooseImage"}
          style={{ height: props.height ? props.height : "150px" }}
        >
          {!props.image && (
            <div>
              <Label>Drop or select file</Label>

              <i className="mdi mdi-cloud-upload-outline d-block"></i>
            </div>
          )}
          {props.image && (
            <>
              {fileType == "img" && (
                <img
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  src={props.image}
                />
              )}
              {fileType == "video" && (
                <video
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "block",
                  }}
                >
                  <source src={props.image} />
                </video>
              )}
              <i
                onClick={removeImage}
                style={{
                  position: "absolute",
                  top: "15px",
                  right: "20px",
                  fontSize: "20px",
                  cursor: "pointer",
                  display: props.hideRemoveIcon ? "none" : "block",
                  zIndex: 9,
                }}
                className="mdi mdi-close"
              ></i>
            </>
          )}
          <input
            type="file"
            style={{
              position: "absolute",
              opacity: "0",
              top: "0px",
              left: "0px",
              width: "100%",
              height: "100%",
            }}
            disabled={props.disabled}
            className="form-control"
            accept={fileType == 'img' ? ".png,.jpg,.jfif,.jpeg" :".mp4"}
            onChange={(e) => props.handleChooseImage(e.target.files[0])}
          />
        </div>
      </Col>
    </Row>
  );
}
