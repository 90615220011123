import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
// //Import Scrollbar
import SimpleBar from "simplebar-react";
// MetisMenu
import MetisMenu from "metismenujs";
import { Link, useLocation } from "react-router-dom";
import { PERMISSIONS } from "../../api/permissions";
import ProfileMenu from "./ProfileMenu";
import GlobalLangSwitcher from "./GlobalLangSwitcher";

import arFlag from "../../assets/images/Flag-Egypt.png";
import enFlag from "../../assets/images/Flag-Britain.png";
import i18next from "i18next";

const SidebarContent = (props) => {
  const { t } = useTranslation();

  const location = useLocation();
  const ref = useRef();
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, []);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  useEffect(() => {
    document.body.classList.remove("sidebar-enable");
  }, [location]);

  const permissions = JSON.parse(localStorage.getItem("permissions")) || [];

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ProfileMenu />

          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <a href="#" className="has-arrow">
                {t("Language")}
              </a>

              <ul className="sub-menu">
                {i18next.language == "en" && (
                  <li
                    onClick={() => {
                      localStorage.setItem("lang", "ar");
                      i18next.changeLanguage("ar");
                    }}
                  >
                    <a>
                      <img
                        src={arFlag}
                        style={{ width: "20px", marginRight: "10px" }}
                      />
                      <span>{t("Arabic")}</span>
                    </a>
                  </li>
                )}
                {i18next.language == "ar" && (
                  <li
                    onClick={() => {
                      localStorage.setItem("lang", "en");
                      i18next.changeLanguage("en");
                    }}
                  >
                    <a>
                      <img
                        src={enFlag}
                        style={{ width: "20px", marginRight: "10px" }}
                      />
                      <span>{t("English")}</span>
                    </a>
                  </li>
                )}
              </ul>
            </li>
            {permissions.includes(PERMISSIONS.ADMIN_VIEW) && (
              <li>
                <Link to="/admins">
                  <i className="bx bx-user"></i>
                  <span>{t("Administrators")}</span>
                </Link>
              </li>
            )}
            
            {permissions.includes(PERMISSIONS.ADMIN_VIEW) && <li>
              <Link to="/logs">
                <i className="bx bx-check"></i>
                <span>{t("Logs")}</span>
              </Link>
            </li>}
            {permissions.includes('permission-SEO-view') && <li>
              <Link to="/pages-seo">
                <i className="bx bx-search"></i>
                <span>{t("SEO")}</span>
              </Link>
            </li>}
            {permissions.includes('permission-slide-view') && <li>
              <Link to="/home-slides">
                <i className="bx bxs-home"></i>
                <span>{t("Home Slider")}</span>
              </Link>
            </li>}

            {permissions.includes('permission-developer-view') && <li>
              <Link to="/developers">
                <i className="bx bx-buildings"></i>
                <span>{t("Developers")}</span>
              </Link>
            </li>}

            {permissions.includes('permission-location-create') && <li>
              <Link to="/locations">
                <i className="bx bx-map"></i>
                <span>{t("Locations")}</span>
              </Link>
            </li>}

            {permissions.includes('permission-post-view') && <li>
              <a href="#" className="has-arrow">
                {t("Insights")}
              </a>

              <ul className="sub-menu">
                <li>
                  <Link to="/categories">
                    <i className="bx bxs-shapes"></i>
                    <span>{t("News Categories")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/posts">
                    <i className="bx bx-news"></i>
                    <span>{t("News posts")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/file-manager">
                    <i className="bx bx-file"></i>
                    <span>{t("File manager")}</span>
                  </Link>
                </li>
              </ul>
            </li>}

            {permissions.includes('permission-contact-view') && <li>
              <a href="#" className="has-arrow">
                {t("Leads")}
              </a>

              <ul className="sub-menu">
                <li>
                  <Link to="/contacts">
                    <i className="bx bx-check"></i>
                    <span>{t("Contact us")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/get-in-touch">
                    <i className="bx bx-check"></i>
                    <span>{t("Get in touch")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/newsletter">
                    <i className="bx bx-check"></i>
                    <span>{t("Newsletter")}</span>
                  </Link>
                </li>
              </ul>
            </li>}

            {permissions.includes('permission-event-view') && <li>
              <a href="#" className="has-arrow">
                {t("Events")}
              </a>

              <ul className="sub-menu">
                <li>
                  <Link to="/events">
                    <i className="bx bx-calendar-event"></i>
                    <span>{t("Events")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/events-registrations">
                    <i className="bx bx-calendar-event"></i>
                    <span>{t("Events Registrations")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/interestedin-options">
                    <i className="bx bx-calendar-event"></i>
                    <span>{t("Interested in options")}</span>
                  </Link>
                </li>
              </ul>
            </li>}
            {permissions.includes('permission-career-view') &&<li>
              <a href="#" className="has-arrow">
                {t("Careers")}
              </a>

              <ul className="sub-menu">
                {permissions.includes('permission-career-view') && <li>
                  <Link to="/careers">
                    <i className="bx bx-copy-alt"></i>
                    <span>{t("Job posts")}</span>
                  </Link>
                </li>}

                {permissions.includes('permission-career-view') &&<li>
                  <Link to="/applicants/has-career">
                    <i className="bx bx-copy-alt"></i>
                    <span>{t("Job applicants")}</span>
                  </Link>
                </li>}
                {permissions.includes('permission-career-view') &&<li>
                  <Link to="/applicants">
                    <i className="bx bx-copy-alt"></i>
                    <span>{t("Generic Applicants")}</span>
                  </Link>
                </li>}
                {permissions.includes('permission-department-view') && <li>
                  <Link to="/departments">
                    <i className="bx bxs-grid"></i>
                    <span>{t("Departments")}</span>
                  </Link>
                </li>}
                {permissions.includes('permission-section-view') &&<li>
                  <Link to="/pages/careers/sections">
                    <i className="bx bx-check"></i>
                    <span>{t("Sections")}</span>
                  </Link>
                </li>}
              </ul>
            </li>}

            {permissions.includes('permission-member-view') &&<li>
              <Link to="/members">
                <i className="bx bx-group"></i>
                <span>{t("Team Members")}</span>
              </Link>
            </li>}

            {permissions.includes('permission-service-view') &&<li>
              <Link to="/services">
                <i className="bx bx-check"></i>
                <span>{t("What we do")}</span>
              </Link>
            </li>}

            {permissions.includes('permission-section-view') &&<li>
              <Link to="/pages/our-story/sections">
                <i className="bx bx-check"></i>
                <span>{t("Our story")}</span>
              </Link>
            </li>}
            
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

export default SidebarContent;
