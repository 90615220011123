import { useEffect, useState } from "react"
import { useRecoilState } from "recoil"
import langState from "../atom/lang"
import { sanitizeMultilangObj } from "./utils"

export const useGetInstence = ({apiMeth, getInstence, multilangFields}) => {

  const [fullInstence, setFullInstence] = useState(null)
  const [instence, setInstence] = useState(null)

  const [lang] = useRecoilState(langState)

  const [loading, setLoading] = useState(true)

  const [error, setError] = useState("")

  const [success, setSuccess] = useState(false)

  const [payload, setPayload] = useState({})

  useEffect(() => {
    setLoading(true)
    apiMeth()
      .then(res => {
        setFullInstence(getInstence(res.data.data))
       
        setLoading(false)
      })
      .catch(err => {
        console.error(err)
        setError(err.response.data.errors[0])
        setLoading(false)
      })
  }, [payload])

  useEffect(() => {
    if(fullInstence){
      setInstence(
        sanitizeMultilangObj(fullInstence, multilangFields, lang)
      )
    }
  }, [lang, fullInstence])

  return {
    instenceSuccess: success,
    instenceLoading: loading,
    instenceError: error,
    setPayload,
    instence
  }
}
