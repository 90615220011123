import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
} from "reactstrap";

import { useHistory } from "react-router-dom";

// users
import { useLogout } from "../../hooks/useLogout";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const ProfileMenu = () => {
  // Declare a new state variable, which we'll call "menu"
  const [open, setOpen] = useState(false);

  const { setLogout } = useLogout();

  return (
    <div className="sidebar-profile mb-4">
      <div
        className="d-flex toggler justify-content-between align-items-center "
        onClick={() => setOpen(!open)}
      >
        <div className="d-flex align-items-center " style={{ gap: "15px" }}>
          <span className="avatar-img rounded-circle bg-info text-white">
            {JSON.parse(localStorage.getItem("user"))
              .email.charAt(0)
              .toUpperCase()}
          </span>
          <span className="text-white">
            {JSON.parse(localStorage.getItem("user")).email}
          </span>
        </div>

        {!open && <i
          className="mdi mdi-chevron-down text-white"
          style={{ fontSize: "25px" }}
        ></i>}
        {open && <i
          className="mdi mdi-chevron-up text-white"
          style={{ fontSize: "25px" }}
        ></i>}
      </div>
      <Collapse isOpen={open} className="collapse-menu">
       {/* <Link to="/edit-profile" className="d-block text-white mb-2">
          <i className="bx bx-user align-middle me-3" />
          {t("pages.Profile")}
        </Link>*/}

        <a
          onClick={() => {
            setLogout(true);
          }}
          className="text-white d-block"
        >
          <i className="bx bx-log-out align-middle me-3"></i>
          <span>{t("Logout")}</span>
        </a>
      </Collapse>
    </div>
  );
};

export default ProfileMenu;
