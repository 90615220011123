import React, { useState } from "react";
//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { PERMISSIONS } from "../../../api/permissions";
import TableAction from "../../../components/TableAction";
import { usePagination } from "../../../hooks/usePagination";
import CustomTable from "../../../components/CustomTable/CustomTable";
import { deleteApplicant, getApplicants } from "../../../api/applicants";
import PaginationHandler from "../../../components/PaginationHandler";
import { Button, Input, Label } from "reactstrap";
import { myAxios } from "../../../api/axios";
import {
  deleteInTouchSubmission,
  exportInTouchSubmissions,
  getInTouchSubmissions,
} from "../../../api/getInTouch";
import { ExportBtn } from "../../../components/ExportBtn";
import DateFilter from "../../../components/DateFilter";
import { t } from "i18next";
import moment from "moment";
import { deleteFile, getFiles } from "../../../api/file-manager";
import FileFormModal from "./FileFormModal";

export default function FileManager() {
  const {
    response,
    loading,
    error,
    setPage,
    page,
    setMeta,
    totalPages,
    setPayload,
  } = usePagination(getFiles);

  const [copied, setCopied] = useState(false);

  function copy(txt) {
    navigator.clipboard.writeText(txt);
    setCopied(true);
    setTimeout(() => setCopied(false), 1500);
  }

  const [addOpen, setAddOpen] = useState(false);

  return (
    <div className="page-content">
      <div className="container-fluid">
          <Breadcrumbs
            hasAddBtn={true}
            onAddBtnClick={() => setAddOpen(true)}
            title={"File manager"}
            isMainPage={true}
          />

        {copied && (
          <div
            style={{
              backgroundColor: "black",
              borderRadius: "6px",
              padding: "10px 30px",
              position: "fixed",
              bottom: "100px",
              left: "50%",
              transform: "translateX(-50%)",
              color: "white",
              fontSize: "18px"
            }}
          >
            Link copied to clipboard!
          </div>
        )}

        <CustomTable
          emptyMsg={"No Files Found."}
          data={response?.data}
          loading={loading}
          error={error}
          cols={[
            {
              label: "Url",
              key: "url",
              renderer: (row) => (
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => copy(row.url)}
                >
                  {row.url}
                </span>
              ),
              style: { width: "60%" },
            },

            {
              label: "Date",
              key: "date",
              renderer: (row) => moment(row.createdAt).format("DD MMM YYYY"),
              style: { width: "20%" },
            },

            {
              label: "Action",
              key: "action",
              style: { width: "20%", textAlign: "right" },
              renderer: (row) => {
                return (
                  <>
                    <TableAction
                      hasDelete={true}
                      editPermission={PERMISSIONS.CATEGORY_EDIT}
                      deletePermission={PERMISSIONS.CATEGORY_DELETE}
                      id={row.id}
                      deleteApi={deleteFile}
                      onDeleteSuccess={() => setPayload({})}
                    />
                  </>
                );
              },
            },
          ]}
        />

        <FileFormModal
          isShow={addOpen}
          handleClose={() => setAddOpen(false)}
          handleRefresh={() => setPayload({ ref: true })}
        />
        {response.data && !loading && (
          <PaginationHandler
            page={page}
            setPage={setPage}
            totalPages={totalPages}
          />
        )}
      </div>
    </div>
  );
}
