import { myAxios } from "./axios"

export function getServices(data, { page, perPage }) {
    let config = {
        params: {
            page,
            perPage,
            filter: data

        },
    }
    return myAxios.get("/services", config)
}

export function searchAndExport(data) {
    let config = {
        params: {
            filter: data
        },
    }
    return myAxios.get("/services/export", config)
}

export function getService(id) {
    return myAxios.get("/services/" + id)
}

export function addService(data) {
    return myAxios.post("/services/create", data)
}

export function editService(id, data) {
    return myAxios.put("/services/" + id + "/update", data)
}

export function deleteService(id) {
    return myAxios.delete("/services/" + id + "/delete")
}
