import Breadcrumbs from "../../../components/Common/Breadcrumb";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { usePagination } from "../../../hooks/usePagination";
import PaginationHandler from "../../../components/PaginationHandler";
import { deleteSeo, getSeos } from "../../../api/seos";
import TableAction from "../../../components/TableAction";
import { PERMISSIONS } from "../../../api/permissions";

import CustomTable from "../../../components/CustomTable/CustomTable";

export default function SeosList() {
  const {
    response,
    loading,
    error,
    setPage,
    page,
    setPerPage,
    totalPages,
    setPayload,
  } = usePagination(getSeos);
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs
          title={"Seo"}
          isMainPage={true}
        />

        <CustomTable
          data={response?.data?.map(p => p.page == 'home'? ({...p, page: "homepage"}) : p)}
          loading={loading}
          error={error}
          cols={[
         
            { label: "Page", key: "page", style: { width: "20%", textTransform:"capitalize" } },
          
            {
              label: "Action",
              key: "action",
              style: { width: "80%", textAlign: "right" },
              renderer: (row) => {
                return (
                  <>
                    <TableAction
                      hasEdit={true}
                      editPermission={PERMISSIONS.CATEGORY_EDIT}
                      deletePermission={PERMISSIONS.CATEGORY_DELETE}
                      editUrl={"/edit-seo/" + row.id}
                      id={row.id}
                    />
                  </>
                );
              },
            },
          ]}
        />

        {response.data && !loading && (
          <PaginationHandler
            page={page}
            setPage={setPage}
            totalPages={totalPages}
          />
        )}
      </div>
    </div>
  );
}
