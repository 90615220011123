import React, { useState } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import i18next from "i18next"

const NonAuthLayout = props => {
  const [lang, setLang] = useState(i18next.language)
  return <React.Fragment>
    {props.children}
    {/* <div style={{width: "100px", margin: "auto"}}>
      <select className="form-control" value={lang} onChange={(e) => {
        i18next.changeLanguage(e.target.value)
        setLang(e.target.value)
        }}>
        <option value={"ar"}>العربية</option>
        <option value={"en"}>English</option>
      </select>
    </div> */}
    </React.Fragment>
}

NonAuthLayout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object,
}

export default withRouter(NonAuthLayout)
