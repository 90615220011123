

import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    Input,
    Container,
    FormFeedback,
    Form,
} from "reactstrap"

import React from "react"

import { useTranslation } from "react-i18next"
import {FORM_FIELDS} from './CONSTANT'
import {FieldInput} from '../../../components/FieldInput'

export default function CareerForm({validation}){
    const { t } = useTranslation()

    return(

        <Row>
            {FORM_FIELDS.map(f => (<FieldInput key={f.key} f={f} validation={validation} />))}
           
        </Row>

    )
}