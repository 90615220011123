import React, { useEffect, useState } from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Container,
  FormFeedback,
  Form, Table,
} from "reactstrap"
// Formik validation
import { useFormik } from "formik"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useHistory, useParams } from "react-router-dom"
import { editRole, getRole, addRole } from "../../../api/roles"
import ResultHandler from "../../../components/ResultHandler"
import FormButtonHandler from "../../../components/FormButtonHandler"
import { getAllPermissions } from "../../../api/admins"
import useCustomValidation from "../validationSchema"
import { useResponse } from "../../../hooks/useResponse"
import { useTranslation } from "react-i18next"
import { useGetInstence } from "../../../hooks/useGetInstence"
import { usePagination } from "../../../hooks/usePagination"
import SearchSelectBox from "../../../components/SearchSelectBox"
import Select from "react-select"
import RoleForm from "./RoleForm"



const AddRole = () => {
  const { t } = useTranslation()
  let navigate = useHistory()
  const { roleSchema } = useCustomValidation()

  const { setData, loading, error, success } = useResponse(addRole, () => {
    navigate.push("/roles")
  })

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: "",
      permissions: [],
      fields: [],
    },
    validationSchema: roleSchema,
    onSubmit: values => {
      let data = {
        ...values,
      }
      setData({ type: "add", data })
    },
  })

  // get all permissions

  const { loading: permissionsLoading, response: permissionsResponse } =
    usePagination(getAllPermissions)



  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={t("pages.Roles")}
            breadcrumbItem={t("btns.Add") + " " + t("pages.Role")}
          />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  {permissionsResponse.data && (
                    <Form
                      className="needs-validation"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                     <RoleForm validation={validation} permissionsResponse={permissionsResponse} />

                      <FormButtonHandler btnTxt="Add" loading={loading} />
                    </Form>
                  )}

                  <ResultHandler
                    // loading={instenceLoading}
                    error={error}
                    success={success}
                    successMsg={"Updated Successfully!"}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddRole
